
import useCRUD from '../../services/CRUDServices';
import VoirArticleComponent from '../../components/article/VoirArticleComponent.vue';
import VoirArticleLoadingComponent from '../../components/article/VoirArticleLoadingComponent.vue';
import router from '../../router/router';
import axiosClient from '../../axios'
import VoirArticleParDepotComponent from '../../components/article/VoirArticleParDepotComponent.vue'

import { onBeforeMount, onMounted, ref } from 'vue';

const { entity, loading, find } = useCRUD('/article');

export default {
    setup() {

        const articleParDepot = ref([])

        onBeforeMount(async (): Promise<any> => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            await find(id)
        })

        onMounted(async () => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            articleParDepot.value = await axiosClient.get('/article/voir-par-depot/' + id)
        })

        return {
            entity, loading, articleParDepot
        }
    },

    components: {
        VoirArticleComponent,
        VoirArticleLoadingComponent,
        VoirArticleParDepotComponent
    },

}
