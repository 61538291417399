
import useCRUD from '../../services/CRUDServices';
import ArticleFormLoadingComponent from '../../components/article/ArticleFormLoadingComponent.vue';
import ArticleFormComponent from '../../components/article/ArticleFormComponent.vue';
import { defineComponent, onBeforeMount } from 'vue';
import router from '../../router/router';

const { entity, find, loading } = useCRUD('/article');

export default defineComponent({
    setup() {
        onBeforeMount(async (): Promise<any> => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            await find(id);
        })

        return {
            entity, find, loading,
        }
    },

    components: {
        ArticleFormLoadingComponent,
        ArticleFormComponent
    }

});
