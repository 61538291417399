
import router from "../../router/router";
import useCRUD from "../../services/CRUDServices";
import { defineComponent, onBeforeMount } from "vue";
import DepotFormComponent from "../../components/depot/DepotFormComponent.vue";
import DepotFormLoadingComponent from "../../components/depot/DepotFormLoadingComponent.vue";
import { Skeletor } from "vue-skeletor";

const { loading, entity, find } = useCRUD('/depot');

export default defineComponent({
    setup() {

        onBeforeMount(async (): Promise<any> => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            await find(id);
        });

        return {
            loading,
            entity,
            find,
        };
    },
    components: { DepotFormLoadingComponent, DepotFormComponent, Skeletor }
});

