

import ProfileAvatar from 'vue-profile-avatar'
import VoirFournisseurLoadingComponent from '../../components/fournisseur/VoirFournisseurLoadingComponent.vue';
import VoirFournisseurComponent from '../../components/fournisseur/VoirFournisseurComponent.vue';
import useCRUD from '../../services/CRUDServices';
import { defineComponent, onBeforeMount } from 'vue';
import router from '../../router/router';

const { loading, entity, find } = useCRUD('/fournisseur');

export default defineComponent({
    setup() {
        onBeforeMount(async (): Promise<any> => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            await find(id);
        })

        return {
            loading, entity,
        }
    },

    components: {
        ProfileAvatar,
        VoirFournisseurLoadingComponent,
        VoirFournisseurComponent
    },

});
