
import VoirClientLoadingComponent from '../../components/client/VoirClientLoadingComponent.vue';
import { defineComponent, onBeforeMount } from 'vue';
import useCRUD from '../../services/CRUDServices';
import VoirClientComponent from '../../components/client/VoirClientComponent.vue';
import router from '../../router/router';

const { entity, find, loading } = useCRUD('/client');

export default defineComponent({
    setup() {
        onBeforeMount(async (): Promise<any> => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            await find(id)
        })

        return {
            entity, find, loading,
        }
    },

    components: {
        VoirClientLoadingComponent,
        VoirClientComponent
    },

});
