import { resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Table, {
      name: "article",
      data: _ctx.articles,
      columns: { reference: 'Réference', designation: 'Désignation', unite: 'Unité', quantity: 'Quantité' },
      actions: true,
      onOnDeleteItem: _ctx.deleteItem
    }, null, 8 /* PROPS */, ["data", "onOnDeleteItem"]),
    _createCommentVNode("table class=\"table table-striped table-hover\">\n        <thead class=\"bg-secondary text-white\">\n            <tr>\n                <th>Réference</th>\n                <th>Designation</th>\n                <th>Stock d'alerte</th>\n                <th>Unité</th>\n                <th class=\"text-center\">Actions</th>\n            </tr>\n        </thead>\n        <tbody v-if=\"articles.length > 0\">\n            <tr v-for=\"(article, index) in articles\" v-bind:key=\"article.id\">\n                <td>{{ article.reference }}</td>\n                <td>{{ article.designation }}</td>\n                <td>{{ article.stock_alert ?? \"Non définie\" }}</td>\n                <td>{{ article.unite }}</td>\n                <td class=\"d-flex justify-content-center\">\n                    <router-link v-if=\"true\" :to=\"{ name: 'article.voir', params: { id: article.id }}\" class=\"btn btn-info btn-sm me-2 text-white\"><i class=\"fa fa-eye\"></i></router-link>\n                    <router-link v-if=\"true\" :to=\"{ name: 'article.modifier', params: { id: article.id }}\" class=\"btn btn-primary btn-sm me-2\"><i class=\"fa fa-edit\"></i></router-link>\n                    <DeleteBtn v-if=\"true\" type=\"danger\" @click.prevent=\"confirmDeletion(article.id, index)\"/>\n                </td>\n            </tr>\n        </tbody>\n        <tbody v-else>\n            <tr>\n                <td class=\"text-center\" colspan=\"9\">Aucun article</td>\n            </tr>\n        </tbody>\n    </table")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}