
import { defineComponent, onBeforeMount, Ref, ref } from "vue";

export default defineComponent({

    props: {
        multiple: {
            type: Boolean,
            required: false,
            default: false,
        },

        default: {
            type: String,
            required: false,
            default: null,
        },
    },

    emits: ['fileChanged'],

    setup(props, { emit }) {
        const paths: Ref<Array<any>> = ref([]);

        const handleFileChange = (e: Event) => {
            if (e.target) {
                const input = e.target as HTMLInputElement
                const reader = new FileReader();

                if (input.files)
                {
                    paths.value = [];
                    Array.from(input.files).forEach((file: File) => {
                        reader.readAsDataURL(file);
                        reader.onload = (e: ProgressEvent<FileReader>): any => {
                            paths.value.push(e.target?.result)
                        }
                    })
                }

                emit('fileChanged', input.files)
            }
        }

        onBeforeMount((): void => {
            if (props.default !== null && props.default !== undefined) paths.value.push(props.default);
        })

        return {
            handleFileChange, paths,
        }
    }

});

