

import { defineComponent, onBeforeMount } from 'vue';
import FournisseurFormComponent from '../../components/fournisseur/FournisseurFormComponent.vue';
import FournisseurFormLoadingComponent from '../../components/fournisseur/FournisseurFormLoadingComponent.vue';
import router from '../../router/router';
import useCRUD from '../../services/CRUDServices';

const { loading, entity, find } = useCRUD('/fournisseur')

export default defineComponent({
    setup() {
        onBeforeMount(async (): Promise<any> => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            await find(id);
        })

        return {
            entity, loading, find,
        }
    },

    components: {
        FournisseurFormComponent,
        FournisseurFormLoadingComponent
    },

});
