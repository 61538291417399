
import { defineComponent } from 'vue';


export default defineComponent({
    props: {
        value: {
            type: Number,
            required: true,
        },
    },
})

