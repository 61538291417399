import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xl-6 mb-3" }
const _hoisted_3 = { class: "col-xl-6 mb-3" }
const _hoisted_4 = { class: "col-xl-6 mb-3" }
const _hoisted_5 = { class: "col-xl-6 mb-3" }
const _hoisted_6 = { class: "col-xl-12 mb-3" }
const _hoisted_7 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeletor = _resolveComponent("Skeletor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Skeletor, {
        height: "40",
        width: "100%",
        style: {"border-radius":"3px"}
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Skeletor, {
        height: "40",
        width: "100%",
        style: {"border-radius":"3px"}
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Skeletor, {
        height: "40",
        width: "100%",
        style: {"border-radius":"3px"}
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Skeletor, {
        height: "40",
        width: "100%",
        style: {"border-radius":"3px"}
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_Skeletor, {
        height: "40",
        width: "100%",
        style: {"border-radius":"3px"}
      })
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_Skeletor, {
        height: "40",
        width: "10%",
        style: {"border-radius":"3px"}
      })
    ])
  ]))
}