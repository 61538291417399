
import { defineComponent, onBeforeMount, ref, Ref } from "vue";
import Input from "../../components/html/Input.vue";
import SaveBtn from "../../components/html/SaveBtn.vue";
import useCRUD from "../../services/CRUDServices";
import ToggleSwitch from "../html/ToggleSwitch.vue";

const { create, update, creating, updating, errors, success } =
  useCRUD("/depot");

type Form = {
  nom: string;
  localisation: string;
  contact: string;
  point_vente: boolean;
  isDefault: boolean;
};

export default defineComponent({
  props: {
    nouveau: {
      type: Boolean,
      required: true,
      default: true,
    },
    depot: {
      type: Object,
      required: false,
      default: {},
    },
    pointVente: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  components: {
    Input,
    SaveBtn,
    ToggleSwitch,
  },

  setup(props) {
    const form: Ref<Form> = ref({
      nom: "",
      localisation: "",
      contact: "",
      point_vente: false,
      isDefault: false,
    });

    const save = async (): Promise<any> => {
      if (props.nouveau === true) await create(form.value);
      else await update(props.depot.id, form.value, 3);

      window.scrollTo({ top: 0, behavior: "smooth" });
      if (success.value !== null && props.nouveau === true) resetForm();
      success.value = null;
    };

    const resetForm = (): void => {
      form.value = {
        nom: "",
        localisation: "",
        contact: "",
        point_vente: true,
        isDefault: false,
      };
    };

    onBeforeMount(() => {
      if (props.nouveau === false && Object.keys(props.depot).length > 0) {
        form.value = {
          nom: props.depot.nom,
          localisation: props.depot.localisation,
          contact: props.depot.contact,
          point_vente: props.depot.point_vente,
          isDefault: props.depot.isDefault ? true : false,
        };
      }
    });

    return {
      form,
      creating,
      updating,
      errors,
      success,
      save,
    };
  },
});
