
import useCRUD from "../../services/CRUDServices";
import { Skeletor } from 'vue-skeletor';
import { onBeforeMount } from "vue";
import router from "../../router/router";
import DepotFormComponent from "../../components/depot/DepotFormComponent.vue";
import DepotFormLoadingComponent from "../../components/depot/DepotFormLoadingComponent.vue";

const { loading, find, entity } = useCRUD('/depot');

export default {
    components: {
        Skeletor, DepotFormComponent, DepotFormLoadingComponent,
    },
    setup() {

        onBeforeMount(async (): Promise<any> => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            await find(id);
        });

        return {
            loading, find, entity,
        };
    },
};
