<template>
    <div class="col-xl-12">
        <div class="card me-3">
            <div class="card-header bg-white p-3">
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="text-info">Historique de stocks</h5>
                </div>
                <hr />
            </div>
            <div class="card-body">
                <ListeHistoriqueStockLoadingComponent v-if="loading" />
                <ListeHistoriqueStockComponent v-else :entities="getLaravelData(entities)"></ListeHistoriqueStockComponent>
            </div>
            <pagination-bootstrap :totalPages="pagination.totalPages" :currentPage="pagination.currentPage"
                @update:currentPage="getPage"></pagination-bootstrap>
        </div>
    </div>
</template>
<script>

import useCRUD from '../../services/CRUDServices';
import { defineComponent, onBeforeMount, ref, provide } from "vue";
import ListeHistoriqueStockComponent from '../../components/article/ListeHistoriqueStockComponent.vue';
import ListeHistoriqueStockLoadingComponent from "../../components/article/ListeHistoriqueStockLoadingComponent.vue";

import PaginationBootstrap from "../../components/utils/PaginationBootstrap/PaginationBootstrap.vue";
import PaginationBootstrapMixins from "../../components/utils/PaginationBootstrap/PaginationBootstrapMixins.js";
import ParserService from '../../services/ParserService.js';


const { entities, all, loading } = useCRUD('/historique-stocks');


export default {
    components: {
        ListeHistoriqueStockComponent,
        ListeHistoriqueStockLoadingComponent,
        PaginationBootstrap
    },

    mixins: [PaginationBootstrapMixins],

    setup() {
        provide('ParserService', ParserService);

        return {
            entities,
            all,
            loading
        }
    },

    data() {
        return {
            page: 1,
        }
    },

    mounted() {
        this.loadData();
    },

    methods: {
        async getPage(page) {
            this.page = page;
        },
        loadData() {
            all(null, JSON.stringify({ page: this.page, perPage: this.pagination.perPage }));
        },
        getLaravelData(response) {
            var pagination = this.getLaravelPagination(response);
            this.pagination.currentPage = pagination.currentPage;
            this.pagination.totalPages = pagination.totalPages;
            return ParserService.getLaravelData(response);
        },
        getLaravelPagination(response) {
            return ParserService.getLaravelPagination(response);
        }
    },

    watch: {
        page() {
            this.loadData();
        }
    }
}
</script>