import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  action: "",
  method: "post"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-xl-12 mb-3" }
const _hoisted_4 = { class: "col-xl-12 mb-3" }
const _hoisted_5 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_SaveBtn = _resolveComponent("SaveBtn")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Input, {
          modelValue: _ctx.form.libelle,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.libelle) = $event)),
          error: _ctx.errors.libelle,
          required: true
        }, {
          default: _withCtx(() => [
            _createTextVNode("Nom de l'étiquette")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "error"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Input, {
          type: "textarea",
          modelValue: _ctx.form.description,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.description) = $event)),
          error: _ctx.errors.description
        }, {
          default: _withCtx(() => [
            _createTextVNode("Description de l'étiquette")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "error"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_SaveBtn, {
        onClick: _withModifiers(_ctx.save, ["prevent"]),
        loading: _ctx.creating || _ctx.updating
      }, {
        default: _withCtx(() => [
          _createTextVNode("Enregistrer")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick", "loading"])
    ])
  ]))
}