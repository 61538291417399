
import axiosClient from "../../axios";
import { computed, defineComponent, onBeforeMount, ref } from "vue";
import { Skeletor } from "vue-skeletor";
import Table from "../html/Table.vue";
import store from "../../store";

export default defineComponent({
    props: {
        depot: {
            type: Object,
            required: true,
        },
        entrepot: {
            type: Boolean,
            default: false,
            required: true,
        }
    },

    setup(props) {
        const articles = ref([])
        const loading = ref(false);
        const columns = { reference: 'Réference', designation: 'Désignation', unite: 'Unité', 'entree - sortie': 'Quantité', detailsPrix: "PU" }
        const casts = [];

        casts['fullArticle.pivot.pu'] = 'money';

        const getName = computed((): string => {
            if (props.entrepot === true) return "entrepot";
            return "point-de-vente";
        });

        onBeforeMount(async () => {
            const limit: number = 5;

            loading.value = true;
            try {
                let response = await axiosClient.get(`/depot/${props.depot.id}/articles?limit=${limit}`)
                articles.value = response.data;
            } catch (err) {
                console.log(err)
            }

            loading.value = false

        });

        return {
            getName, articles, loading, columns, casts,
        };
    },

    components: {
        Skeletor,
        Table
    }
});

