
import { defineComponent, onBeforeMount } from 'vue';
import { Skeletor } from 'vue-skeletor';
import CommandeFormComponent from '../../../components/commande/CommandeFormComponent.vue';
import CommandeFormLoadingComponent from '../../../components/commande/CommandeFormLoadingComponent.vue';
import router from '../../../router/router';
import useCRUD from '../../../services/CRUDServices';

const { entity, loading, find } = useCRUD('/commandes'); // Contient tous les fonctions CRUD pour le Commande

export default defineComponent({
    components: {
        CommandeFormComponent,
        CommandeFormLoadingComponent,
        Skeletor,
    },

    setup() {
        onBeforeMount(async () => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            await find(id);
        })

        return {
            entity, loading,
        }
    }
})
