import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-label"
}
const _hoisted_2 = {
  key: 0,
  class: "text-danger ms-2"
}
const _hoisted_3 = ["placeholder", "value"]
const _hoisted_4 = ["type", "placeholder", "value"]
const _hoisted_5 = {
  key: 3,
  class: "text-danger mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.hasSlot)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default"),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(*)"))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.type === 'textarea')
      ? (_openBlock(), _createElementBlock("textarea", _mergeProps({
          key: 1,
          class: _ctx.hasErrors === true ? 'border-danger' : ''
        }, _ctx.$attrs, {
          placeholder: _ctx.placeholder,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
          class: "form-control",
          value: _ctx.modelValue
        }), null, 16 /* FULL_PROPS */, _hoisted_3))
      : (_openBlock(), _createElementBlock("input", _mergeProps({
          key: 2,
          class: _ctx.hasErrors === true ? 'border-danger' : ''
        }, _ctx.$attrs, {
          type: _ctx.type === undefined ? 'text' : _ctx.type,
          placeholder: _ctx.placeholder,
          value: _ctx.modelValue,
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
          class: "form-control"
        }), null, 16 /* FULL_PROPS */, _hoisted_4)),
    (_ctx.hasErrors)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.error[0]), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}