
import { defineComponent } from 'vue'
import DevisFormComponent from '../../../components/devis/DevisFormComponent.vue'
import useCRUD from '../../../services/CRUDServices';
import searchFromOldDevisComponent from "../../../components/devis/SearchFromOldDevisComponent.vue"
const { find, loading, entity } = useCRUD('/commandes'); // Contient tous les fonctions CRUD pour le Devis

export default defineComponent({
    components: {
        DevisFormComponent, searchFromOldDevisComponent
    },
    setup() {
        return { find }
    }
});

