<template>
    <table class="table table-striped table-hover">
        <thead class="bg-secondary text-white">
            <tr>
                <th>ID</th>
                <th>Numero</th>
                <th>Date</th>
                <th class="text-center">Actions</th>
            </tr>
        </thead>
        <tbody v-if="TransfertList.length > 0">
            <tr v-for="(transfert, index) in TransfertList" v-bind:key="transfert.id">
                <td>{{ transfert.id }}</td>
                <td>{{ transfert.numero }}</td>
                <td>{{ transfert.date }}</td>
                <td class="d-flex justify-content-center">
                    <router-link v-if="true" :to="{ name: 'transfert.modifier', params: { id: transfert.id } }"
                        class="btn btn-info btn-sm me-2 text-white"><i class="fa fa-eye"></i></router-link>
                    <router-link v-if="true" :to="{ name: 'transfert.modifier', params: { id: transfert.id } }"
                        class="btn btn-primary btn-sm me-2"><i class="fa fa-edit"></i></router-link>
                    <DeleteBtn v-if="true" type="danger" @click.prevent="confirmDeletion(transfert.id, index)" />
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td class="text-center" colspan="9">Aucun client</td>
            </tr>
        </tbody>
    </table>
    <pagination-bootstrap :totalPages="pagination.totalPages" :currentPage="pagination.currentPage"
        @update:currentPage="getPage"></pagination-bootstrap>
</template>
<script>
import axiosClient from '../../axios';
import DeleteBtn from '../html/DeleteBtn.vue'
import SimpleAlert from 'vue3-simple-alert';
import useCRUD from '../../services/CRUDServices';

import Flash from '../../functions/Flash';
const { destroy } = useCRUD('/transfert-article');

import { computed, onMounted, onBeforeMount, ref, defineComponent, Ref, watch, provide } from 'vue';

import PaginationBootstrap from "../../components/utils/PaginationBootstrap/PaginationBootstrap.vue";
import PaginationBootstrapMixins from "../../components/utils/PaginationBootstrap/PaginationBootstrapMixins.js";
import ParserService from '../../services/ParserService.js';

export default {
    components: {
        DeleteBtn,
        PaginationBootstrap
    },

    mixins: [PaginationBootstrapMixins],

    setup() {
        provide('ParserService', ParserService);

        return {}
    },

    data() {
        return {
            page: 1,
            TransfertList: [],
        }
    },

    computed: {
        except() {
            return JSON.stringify({
                page: this.page,
                perPage: this.pagination.perPage
            })
        }
    },

    methods: {
        getList() {
            axiosClient.get(`/transfert-article?except=${this.except}`).then(({ data }) => {
                data = this.getLaravelData(data);
                while (this.TransfertList.pop());
                data.forEach(element => {
                    this.TransfertList.push(element);
                });
            })
        },
        async confirmDeletion(id, index) {
            await SimpleAlert.confirm("Voulez-vous supprimer ce transfert ?", "Question", "question").then(() => {
                Flash('loading', "Chargement", "Suppression en cours", 1, false)
                //destroy(id, props.entities, index)
            }).catch((error) => {
                if (error !== undefined) {
                    Flash('error', "Message d'erreur", "Impossible de supprimer ce transfert")
                }
            });
        },
        getLaravelData(response) {
            var pagination = this.getLaravelPagination(response);
            this.pagination.currentPage = pagination.currentPage;
            this.pagination.totalPages = pagination.totalPages;
            return ParserService.getLaravelData(response);
        },
        getLaravelPagination(response) {
            return ParserService.getLaravelPagination(response);
        },
        async getPage(page) {
            this.page = page;
        },
    },
    mounted() {
        this.getList();
    },
    watch: {
        page() {
            this.getList();
        }
    }
    // setup() {
    //     // -------- State ---------
    //     let TransfertList = ref([])
    //     // -------- State ---------
    //     provide('ParserService', ParserService);

    //     // ------- methodes --------
    //     const getList = () => (
    //         axiosClient.get(`/transfert-article?except=${except}`).then(({ data }) => {
    //             TransfertList.value = data
    //             console.log(TransfertList)
    //         })
    //     )

    //     const confirmDeletion = async (id, index) => {
    //         await SimpleAlert.confirm("Voulez-vous supprimer ce transfert ?", "Question", "question").then(() => {
    //             Flash('loading', "Chargement", "Suppression en cours", 1, false)
    //             //destroy(id, props.entities, index)
    //         }).catch((error: undefined) => {
    //             if (error !== undefined) {
    //                 Flash('error', "Message d'erreur", "Impossible de supprimer ce transfert")
    //             }
    //         });
    //     }

    //     // ------- methodes --------

    //     onMounted(() => {
    //         getList()
    //     })

    //     return { TransfertList, confirmDeletion }

    // }
}

</script>
