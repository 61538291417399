import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xl-4 d-flex justify-content-start align-items-center flex-column" }
const _hoisted_3 = { class: "col-xl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeletor = _resolveComponent("Skeletor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Skeletor, {
        class: "mb-3",
        circle: "",
        size: "200"
      }),
      _createVNode(_component_Skeletor, {
        class: "mb-3",
        height: "40",
        width: "100%",
        style: {"border-radius":"3px"}
      }),
      _createVNode(_component_Skeletor, {
        height: "40",
        width: "75%",
        style: {"border-radius":"3px"}
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Skeletor, {
        height: "35",
        width: "100%",
        class: "mb-3",
        style: {"border-radius":"5px"}
      }),
      _createVNode(_component_Skeletor, {
        height: "35",
        width: "100%",
        class: "mb-3",
        style: {"border-radius":"5px"}
      }),
      _createVNode(_component_Skeletor, {
        height: "35",
        width: "100%",
        class: "mb-3",
        style: {"border-radius":"5px"}
      })
    ])
  ]))
}