

import { onBeforeMount } from 'vue';
import router from '../../router/router';
import useCRUD from '../../services/CRUDServices';
import VoirBonLivraisonComponent from '../../components/bon-livraison/VoirBonLivraisonComponent.vue';
import VoirBonLivraisonLoadingComponent from '../../components/bon-livraison/VoirBonLivraisonLoadingComponent.vue';

const { loading, find, entity } = useCRUD('/bon-livraisons');

export default {
    setup() {

        onBeforeMount(async () => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            await find(id)
        })

        return {
            loading, entity,
        }
    },

    components: {
        VoirBonLivraisonComponent,
        VoirBonLivraisonLoadingComponent
    },

}
