
import axiosClient from '../../axios';
import router from '../../router/router';
import Table from '../../components/html/Table.vue';
import { defineComponent, onBeforeMount, ref } from 'vue';

export default defineComponent({
    components: {
        Table
    },

    setup() {
        const loading = ref(false);
        const articles = ref([]);
        const columns = { reference: 'Réference', designation: 'Désignation', unite: 'Unité', 'entree-sortie': 'Quantité en stock', detailsPrix: 'Prix unitaire' };
        const casts = [];
        const id = parseInt(router.currentRoute.value.params.id.toString());

        casts['fullArticle.pivot.pu'] = 'money';

        const handleShow = (e: { id: number }) => {
            router.push(`/point-de-vente/${id}/gerer-prix/${e.id}`);
        }

        onBeforeMount(async (): Promise<any> => {
            loading.value = true;

            let response = await axiosClient.get(`/depot/${id}/articles`);
            articles.value = response.data;

            loading.value = false;
        })

        return {
            articles, loading, columns, casts, id, handleShow,
        }
    },

});

