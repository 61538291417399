
import Flash from '../../functions/Flash';
import useCRUD from '../../services/CRUDServices';
import DeleteBtn from '../../components/html/DeleteBtn.vue';
import SimpleAlert from 'vue3-simple-alert';
import { computed, defineComponent } from 'vue';

const { deleting, destroy } = useCRUD('/categorie');

export default defineComponent({
    props: {
        categories: {
            type: Array<any>,
            required: true,
        },
        type: {
            type: Number,
            required: true,
        },
    },

    components: {
        DeleteBtn,
    },

    setup(props) {
        const prefix = computed((): string => {
            if (props.type === 1) return "client";
            else if (props.type === 2) return "fournisseur";
            else if (props.type === 3) return "article";
            else throw new Error("Le type de categorie doit être 1 ou 2 ou 3");
        })

        const confirmDeletion = async (id: number, index: number): Promise<any> => {
            await SimpleAlert.confirm("Voulez-vous supprimer ce categorie ?", "Question", "question").then(() => {
                Flash('loading', "Chargement", "Suppression en cours", 1, false)
                destroy(id, props.categories, index)
            }).catch(error => {
                if (error !== undefined) {
                    Flash('error', "Message d'erreur", "Impossible de supprimer ce categorie")
                }
            });
        }

        return {
            deleting, confirmDeletion, prefix,
        }
    },

})
