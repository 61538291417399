export default {

    devis: {
        MAX_ARTICLE: 20,
    },

    commande: {
        MAX_ARTICLE: 10,
    },

}
