
import useCRUD from "../../services/CRUDServices";
import { computed, defineComponent } from "vue";
import Flash from "../../functions/Flash";
import VueSimpleAlert from "vue3-simple-alert";
import DeleteBtn from '../../components/html/DeleteBtn.vue';

const { deleting, destroy } = useCRUD('/depot');

export default defineComponent({
    props: {
        depots: {
            type: Array<any>,
            required: true,
        },
        entrepot: {
            type: Boolean,
            required: true,
            default: false,
        },
    },

    setup(props) {

        const getName = computed((): string => {
            if (props.entrepot === true) return "entrepot";
            return "point-de-vente";
        })

        const editAccess = computed((): string => {
            if (props.entrepot === true) return "edit_entrepot";
            return "edit_point_vente";
        })

        const deleteAccess = computed((): string => {
            if (props.entrepot === true) return "delete_entrepot";
            return "delete_point_vente";
        })

        const confirmDeletion = async (id: number, index: number): Promise<any> => {
            let name = props.entrepot ? 'cet entrepôt' : 'ce point de vente'
            let errorMessage = "Désolé, il est impossible de supprimer un PDV contenant des articles. Veuillez trasnférer le stock vers un autre PDV avant de pouvoir supprimer."

            await VueSimpleAlert.confirm("", "Souhaitez-vous supprimer " + name + " ?", "warning", { cancelButtonText: 'Annuler', }).then(() => {
                Flash('loading', "Chargement", "Suppression en cours", 1, false)
                destroy(id, props.depots, index, errorMessage)
            }).catch((error: undefined) => {
                if (error !== undefined) {
                    Flash('error', "Message d'erreur", errorMessage)
                }
            });
        }

        return {
            confirmDeletion, deleting, destroy, getName, editAccess, deleteAccess,
        };
    },

    components: {
        DeleteBtn,
    },

});

