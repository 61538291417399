

import { Skeletor } from "vue-skeletor";
import GererResponsableFormLoadingComponent from '../../components/depot/GererResponsableFormLoadingComponent.vue';
import GererResponsableFormComponent from '../../components/depot/GererResponsableFormComponent.vue';
import { defineComponent, onBeforeMount } from "vue";
import router from "../../router/router";
import useCRUD from "../../services/CRUDServices";

const Depot = useCRUD('/depot');
const Responsable = useCRUD('/user');

export default defineComponent({
    components: {
        Skeletor, GererResponsableFormComponent, GererResponsableFormLoadingComponent,
    },
    setup() {
        onBeforeMount(async (): Promise<any> => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            await Depot.find(id);
            await Responsable.all(0);
        })

        return {
            Responsable, Depot,
        };
    },
});

