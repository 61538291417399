<template>
    <div class="col-xl-12">
        <div class="card me-3">
            <div class="card-header bg-white p-3">
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="text-info">Liste des devis fournisseur</h5>
                    <router-link to="/devis/fournisseur/nouveau" class="btn btn-primary"><i
                            class="fa fa-plus me-2"></i>Nouveau devis</router-link>
                </div>
            </div>
            <div class="card-body">
                <ListeDevisLoadingComponent v-if="loading" />
                <ListeDevisComponent v-else :entities="getLaravelData(entities)" :appro="true" />
            </div>
            <pagination-bootstrap :totalPages="pagination.totalPages" :currentPage="pagination.currentPage"
                @update:currentPage="getPage"></pagination-bootstrap>
        </div>
    </div>
</template>

<script>
import { defineComponent, onBeforeMount, provide } from 'vue';
import ListeDevisComponent from '../../../components/devis/ListeDevisComponent.vue';
import ListeDevisLoadingComponent from '../../../components/devis/ListeDevisLoadingComponent.vue';
import useCRUD from '../../../services/CRUDServices';

import PaginationBootstrap from "../../../components/utils/PaginationBootstrap/PaginationBootstrap.vue";
import PaginationBootstrapMixins from "../../../components/utils/PaginationBootstrap/PaginationBootstrapMixins.js";
import ParserService from '../../../services/ParserService.js';


const { loading, entities, all } = useCRUD('/commandes')
// await all(1, null, true) // Recuperer les deviss

export default defineComponent({
    components: {
        ListeDevisComponent, ListeDevisLoadingComponent, PaginationBootstrap
    },

    mixins: [PaginationBootstrapMixins],

    setup() {
        provide('ParserService', ParserService);

        return {
            entities,
            all,
            loading
        }
    },

    data() {
        return {
            page: 1,
        }
    },

    mounted() {
        this.loadData();
    },

    methods: {
        async getPage(page) {
            this.page = page;
        },
        async loadData() {
            await all(1, JSON.stringify({ page: this.page, perPage: this.pagination.perPage }), true);
        },
        getLaravelData(response) {
            var pagination = this.getLaravelPagination(response);
            this.pagination.currentPage = pagination.currentPage;
            this.pagination.totalPages = pagination.totalPages;
            return ParserService.getLaravelData(response);
        },
        getLaravelPagination(response) {
            return ParserService.getLaravelPagination(response);
        }
    },

    watch: {
        page() {
            this.loadData();
        }
    }


})
</script>
