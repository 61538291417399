
import router from "../../router/router";
import useCRUD from "../../services/CRUDServices";
import { defineComponent, onBeforeMount } from "vue";
import VoirDepotComponent from "../../components/depot/VoirDepotComponent.vue";
import VoirDepotLoadingComponent from "../../components/depot/VoirDepotLoadingComponent.vue";
import { Skeletor } from 'vue-skeletor';

const { loading, entity, find } = useCRUD('/depot');

export default defineComponent({
    setup() {
        onBeforeMount(async (): Promise<any> => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            await find(id);
        });
        return {
            entity,
            loading,
            find,
        };
    },
    components: { VoirDepotComponent, VoirDepotLoadingComponent, Skeletor }
});

