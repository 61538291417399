
import { defineComponent } from 'vue';
import { Skeletor } from 'vue-skeletor';

export default defineComponent({
    props: {
        hasSub: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    components: {
        Skeletor,
    },

});

