
import { defineComponent, onBeforeMount, ref } from 'vue'
import Input from '../../components/html/Input.vue'
import SaveBtn from '../../components/html/SaveBtn.vue'
import useCRUD from '../../services/CRUDServices'
import { Skeletor } from 'vue-skeletor'
import store from '../../store'

const { entity, create, errors, creating, loading, updating, find, update } = useCRUD("/parametres/generale");

export default  defineComponent({
    components: {
        Input,
        SaveBtn,
        Skeletor,
    },
    setup() {
        const form = ref({
            nom: null,
            contact: null,
            email: null,
            assujeti: true,
            nif: null,
            stat: null,
        });

        const save = async () => {
            if (entity.value === "") await create(form.value);
            else await update(entity.value.id, form.value);

            // Re mettre a jour le store qui contient les informations de l'entreprise
            store.dispatch('getEntrepriseInformations')
        }

        onBeforeMount(async () => {
            await find(null)

            if (entity.value !== "") {
                form.value = {
                    nom: entity.value.generale.nom,
                    contact: entity.value.generale.contact,
                    email: entity.value.generale.email,
                    assujeti: entity.value.generale.assujeti,
                    nif: entity.value.generale.nif,
                    stat: entity.value.generale.stat,
                }
            }
        })

        return {
            form, errors, creating, entity, loading, updating, find, create, save, update,
        }
    }
})

