

import { defineComponent } from 'vue';
import { Skeletor } from 'vue-skeletor';

export default defineComponent({
    props: {
        row: {
            type: Number,
            required: false,
            default: 5,
        },
        column: {
            type: Number,
            required: false,
            default: 4,
        }
    },

    components: { Skeletor }
});

