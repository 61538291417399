
import { computed, ref, Ref, watch, defineComponent } from "vue";

export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: {
      type: null,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    error: {
      type: Array,
      required: false,
      default: [],
    },
    required: {
      type: Boolean,
      required: false,
    },
    filter: {
      type: String,
      required: false,
    },
  },

  emits: ["update:modelValue"],

  setup(props, { emit, slots }) {
    const hasErrors: Ref<boolean> = ref(false);

    const updateValue = (e: Event) => {
      let value = (e.target as HTMLInputElement).value;
      if (value !== "") hasErrors.value = false;
      emit("update:modelValue", value);
    };

    const hasSlot = computed(() => {
      return slots.default !== undefined;
    });

    watch(
      () => props.error,
      (value) => {
        if (value && value.length > 0) {
          hasErrors.value = true;
        }
      }
    );

    return {
      hasErrors,
      updateValue,
      hasSlot,
    };
  },
});
