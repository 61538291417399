
import Input from "../html/Input.vue";
import SaveBtn from "../html/SaveBtn.vue";
import Alert from "../html/Alert.vue";
import MultiSelect from "@vueform/multiselect";
import { Skeletor } from "vue-skeletor";
import useCRUD from "../../services/CRUDServices";
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import CategorieFormComponent from "../categorie/CategorieFormComponent.vue";
import EtiquetteFormComponent from "../etiquette/FormEtiquetteComponent.vue";
import ToggleSwitch from "../html/ToggleSwitch.vue";

const Article = useCRUD("/article"); // Contient tous les fonctions CRUD pour le article
const Categorie = useCRUD("/categorie"); // Contient tous les foncions CRUD pour le categorie
const SousCategorie = useCRUD("/sous-categorie"); // Contient tous les foncions CRUD pour le categorie
const Etiquette = useCRUD("/etiquette"); // contient tous les fonctions CRUD pour l'étiquette

type Form = {
    reference: string | null;
    designation: string | null;
    unite: string;
    stock_alert: number | null;
    categories: Array<any>;
    sous_categories: Array<any>;
    description: string | null;
    etiquettes: Array<any>;
    isDefault: Boolean | null;
};

export default defineComponent({
    name: "Nouvel article",
    components: {
        Input,
        SaveBtn,
        Alert,
        MultiSelect,
        CategorieFormComponent,
        EtiquetteFormComponent,
        Skeletor,
        ToggleSwitch,
    },

    props: {
        nouveau: {
            type: Boolean,
            required: true,
        },
        article: {
            type: Object,
            required: false,
        },

        duplicate: {
            type: Boolean,
            required: false,
        },
    },

    setup(props, { emit }) {
        const form = ref({
            reference: null,
            designation: null,
            unite: "Nombre",
            stock_alert: null,
            categories: [],
            sous_categories: [],
            description: null,
            etiquettes: [],
            isDefault: false,
        } as Form);

        // Crud for categorie
        const creationCategorie: Ref<boolean> = ref(false);

        const categorieCree = async (): Promise<any> => {
            creationCategorie.value = false;
            await Categorie.all(3);
        };

        const creerCategorie = () => {
            creationCategorie.value = !creationCategorie.value;
        };
        // Crud for etiquette
        const creationEtiquette: Ref<boolean> = ref(false);

        const etiquetteCree = async (): Promise<any> => {
            creationEtiquette.value = false;
            await Etiquette.all(3);
        };

        const creerEtiquette = () => {
            creationEtiquette.value = !creationEtiquette.value;
        };

        const save = async (): Promise<any> => {
            if (props.nouveau === true) await Article.create(form.value);
            else if (props.duplicate === true) {
                let data = { ...form.value };

                data.categories = form.value.categories.map((el) => el.id);
                data.sous_categories = form.value.sous_categories.map((el) => el.id);
                data.etiquettes = form.value.etiquettes.map((el) => el.id);

                await Article.create(data);
            } else if (props.article && !props.duplicate) {
                let data = { ...form.value };
                data.sous_categories = form.value.sous_categories.map((el) => el.id);

                await Article.update(props.article.id, data);
            }

            window.scrollTo({ top: 0, behavior: "smooth" });

            if (Article.success.value !== null && props.nouveau === true) {
                resetForm();
                emit("article-cree");
            }
            Article.success.value = null;
        };

        const resetForm = (): void => {
            form.value = {
                reference: null,
                designation: null,
                unite: "Nombre",
                stock_alert: null,
                categories: [],
                sous_categories: [],
                description: null,
                etiquettes: [],
                isDefault: false,
            };
        };

        const categorieCloseCheck = async (e: { modelValue: string | any[] }) => {
            await getSousCategorie();
            check(e);
        };

        const check = (e: { modelValue: string | any[] }): void => {
            if (e.modelValue.length > 0) Article.errors.value.categories = null;
        };

        const hasError = computed((): boolean => {
            if (
                Article.errors.value.categories &&
                Article.errors.value.categories.length > 0
            )
                return true;
            return false;
        });

        const getSousCategorie = async () => {
            let except: string | null;
            if (props.nouveau && !props.duplicate) {
                except = JSON.stringify(form.value.categories);
            } else {
                except = JSON.stringify(form.value.categories.map((el) => el.id));
            }
            await SousCategorie.all(null, except);
        };

        onMounted(async (): Promise<any> => {
            await Categorie.all(3, "", true);
            await Etiquette.all(3);

            if ((props.nouveau === false && props.article) || props.duplicate) {
                if (props.article) {
                    form.value = {
                        reference: props.article.reference,
                        designation: props.article.designation,
                        unite: props.article.unite,
                        stock_alert: props.article.stock_alert,
                        categories: props.article.categories_reel,
                        sous_categories: [],
                        description: props.article.description,
                        etiquettes: props.article.etiquettes,
                        isDefault: props.article.isDefault ? true : false,

                    };

                    getSousCategorie();

                    form.value.sous_categories = props.article.sous_categories;
                }
            }
        });

        return {
            Article,
            Categorie,
            SousCategorie,
            Etiquette,
            form,
            save,
            hasError,
            check,
            categorieCree,
            creationCategorie,
            creerCategorie,
            etiquetteCree,
            creationEtiquette,
            creerEtiquette,
            getSousCategorie,
            categorieCloseCheck,
        };
    },
});
