<template>
    <div class="row">
        <div class="col-xl-6 mb-4">
            <Skeletor height="40" width="100%" style="border-radius: 3px" />
        </div>
        <div class="col-xl-6 mb-4">
            <Skeletor height="40" width="100%" style="border-radius: 3px" />
        </div>
        <div class="col-xl-3 mb-4">
            <Skeletor height="40" width="100%" style="border-radius: 3px" />
        </div>
        <div class="col-xl-3 mb-4">
            <Skeletor height="40" width="100%" style="border-radius: 3px" />
        </div>

        <div class="col-xl-6 mb-4">
            <Skeletor height="40" width="100%" style="border-radius: 3px" />
        </div>

        <div class="col-xl-3 mb-4">
            <Skeletor height="40" width="100%" style="border-radius: 3px" />
        </div>
        <div class="col-xl-3 mb-4">
            <Skeletor height="40" width="100%" style="border-radius: 3px" />
        </div>
        <div class="col-xl-6 mb-4">
            <Skeletor height="40" width="100%" style="border-radius: 3px" />
        </div>

        <div class="d-flex justify-content-end">
            <Skeletor height="40" width="10%" style="border-radius: 3px" />
        </div>
        <div class="d-flex justify-content-end">
            <Skeletor height="40" width="10%" style="border-radius: 3px" />
        </div>
    </div>
</template>

<script>

import { Skeletor } from 'vue-skeletor';

Skeletor

export default {
    components: {
        Skeletor,
    },
}
</script>
