

import DepotFormComponent from '../../components/depot/DepotFormComponent.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        DepotFormComponent,
    },
})

