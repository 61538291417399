
import Input from '../html/Input.vue';
import { Skeletor } from 'vue-skeletor';
import router from '../../router/router';
import Config from '../../config/config';
import SaveBtn from '../html/SaveBtn.vue';
import Flash from '../../functions/Flash';
import MultiSelect from '@vueform/multiselect';
import Datepicker from '@vuepic/vue-datepicker';
import useCRUD from '../../services/CRUDServices';
import { computed, onBeforeMount, ref, defineComponent, Ref } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import SimpleAlert from 'vue3-simple-alert';


const Livraison = useCRUD('/bon-livraisons'); // Contient tous les fonctions CRUD pour le Bon de Livraison
const Depot = useCRUD('/depot'); // Contient tous les fonctions CRUD pour le Bon de Livraison
const { getKey, key } = useCRUD('/commandes');

type Form = {
    numero: string | null,
    date: Date | null,
    adresse_livraison: string | null
    validite?: number,
    articles: Array<any>,
    commande: number, // Numero de la commande qui a generer le bon de livraison
    livreur: string | null,
    contact: string | null,
    type: number, // Pour determiner que c'est un bon de livraison
    mode_livraison: number,
    a_la_charge_de: number,
    cout: number | null
}

export default defineComponent({
    name: "LivraisonFormComponent",
    components: {
        Input, SaveBtn, Datepicker, MultiSelect, Skeletor,
    },

    props: {
        /**
         * Permet de savoir si on veut modifier un commande ou créer une
         * @values true, false
         */
        nouveau: {
            type: Boolean,
            required: false,
        },

        /**
         * Livraison a modifier dans le cas d'une modification
         */
        livraison: {
            type: Object,
            required: false,
        },

        /**
         * Provenance du bon de livraison
         */
        commande: {
            type: Object,
            required: true,
        },
    },

    setup(props) {

        const router = useRouter()

        const form: Ref<Form> = ref({
            numero: null,
            date: null,
            adresse_livraison: null,
            articles: [],
            commande: props.commande.id,
            livreur: null,
            contact: null,
            type: 2,
            mode_livraison: 1,
            a_la_charge_de: 0,
            cout: 0.0
        });

        const valide: Ref<boolean> = ref(true);
        const nombreArticle = ref(1);

        const handleChange = (value: number | null) => {
            if (value !== null) {
                if (value == 1) Depot.all(1);
                else if (value == 2) Depot.all(0);
                else throw new Error("Qui n'est pas un point de vente et ne pas un entrepot")
            }
        }

        const check = (e: { modelValue: null; }) => {
            if (e.modelValue !== null) Depot.errors.value.categories = null
        }

        const hasError = computed((): boolean => {
            if (Depot.errors.value.categories && Depot.errors.value.categories.length > 0) return true
            return false
        })

        const confirmSave = async (): Promise<any> => {
            await SimpleAlert.confirm("Voulez-vous enregister cette livraison ?", "Enregistrement", "question").then(() => {
                save()
            }).catch((error: undefined) => {
                if (error !== undefined) {
                    Flash('error', "Message d'erreur", "Impossible d'enregister cette livraison")
                }
            });
        }

        const save = async () => {
            if (props.nouveau === true) {
                await Livraison.create(form.value)
                if (Livraison.success.value !== null) {
                    router.replace({ query: {} })
                    router.push(`/bon-livraison/voir/${Livraison.entity.value.id}`)
                }
            } else if (props.commande) {
                await Livraison.update(props.commande.id, form.value)
            }

            if (Livraison.entity.value.id != undefined) router.push({
                name: 'bon-livraison.client.voir', params: { id: Livraison.entity.value.id }
            })

            window.scrollTo({ top: 0, behavior: 'smooth' })
            Livraison.success.value = null
        }

        const checkDate = () => {
            Livraison.errors.value.date = null
        }

        const generateArticleArrayFromArticles = (articles: any[]) => {
            articles.forEach((article: null | undefined) => addItem(article))
            if (form.value.articles.length === 0) {
                Flash('error', "Message d'erreur", "Tous les articles de cette commande a déja été récu")
                nombreArticle.value = 0;
                router.push('/commande/fournisseur/liste');
                return;
            }
        }

        const generateArticleArray = (nombreArticle: number) => {
            for (let i = 0; i < nombreArticle; i++) {
                addItem(false)
            }
        }


        const removeItem = (index: number) => {
            form.value.articles.splice(index, 1);
            nombreArticle.value--
        }

        const addItem = (article: any) => {
            if (nombreArticle.value > Config.commande.MAX_ARTICLE) {
                Flash('error', "Message d'erreur", `Nombre d'article maximum atteint. Limite ${Config.commande.MAX_ARTICLE}`)
                return
            }

            if (article.pivot.quantite_recu < article.pivot.quantite) {
                form.value.articles.push({
                    id: article.id,
                    quantite: article.pivot.quantite - article.pivot.quantite_recu,
                    designation: article.designation,
                    total: article.pivot.quantite - article.pivot.quantite_recu,
                    valide: true,
                })
            }
        }

        /**
         * Recuperer la nouvelle numéro du devis et le mettre dans la formulaire
         *
         * @return  {Promise}
         */
        const setLivraisonKey = async (): Promise<any> => {
            await getKey(4) // Clé de bon de livraison
            form.value.numero = key.value
        }

        const dateState = computed(() => {
            if (Livraison.errors.value.date && Livraison.errors.value.date.length > 0) return false
            return null
        })

        const checkQuantite = (index: number): void => {
            valide.value = true;

            const quantite: number = form.value.articles[index].quantite;
            const max: number = form.value.articles[index].total;

            if (quantite > max) {
                Flash('error', "Message d'erreur", "La quantité ne doit pas depasser la quantté totale: " + max.toString());
                form.value.articles[index].valide = false;
            } else if (quantite <= max && quantite > 0) {
                form.value.articles[index].valide = true;
            } else {
                Flash('error', "Message d'erreur", "La quantité ne doit pas être 0");
                form.value.articles[index].valide = false;
            }

            form.value.articles.map(article => {
                valide.value = valide.value && article.valide;
            })
        }

        onBeforeMount(() => {
            // si creation de bon à partir de commande
            if (props.commande !== null && props.commande.id !== undefined) {
                nombreArticle.value = props.commande.articles.length
                form.value.date = props.commande.date
                form.value.adresse_livraison = props.commande.adresse_livraison
                form.value.commande = props.commande.id

                generateArticleArrayFromArticles(props.commande.articles)
            }

            // Création d'une commande a partir de rien
            else {
                //form.value.appro = props.appro;
                generateArticleArray(nombreArticle.value);
            }
            if (props.nouveau === true) setLivraisonKey();
        })

        return {
            Livraison, Flash, form, nombreArticle, setLivraisonKey, getKey, key, checkQuantite,
            dateState, addItem, removeItem, checkDate, save, valide,
            Depot, check, hasError, handleChange, confirmSave
        }
    },

})

