

import store from '../../store';
import { defineComponent, computed } from 'vue';
import { formatDate, totalHT, totalTVA, totalTTC, format, montantTVA, montantTTC } from '../../functions/functions';

export default defineComponent({
    props: {
        commande: {
            type: Object,
            required: true,
        },
        appro: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    setup() {
        const infoEntreprise = store.state.infoEntreprise

        const assujeti = computed((): boolean => {
            return infoEntreprise.generale.assujeti
        })

        return {
            formatDate, totalHT, totalTVA, totalTTC, format, montantTVA, montantTTC, infoEntreprise, assujeti,
        }
    },

})
