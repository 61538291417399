

import { defineComponent } from 'vue';
import Button from './Button.vue';

export default defineComponent({
    components: {
        Button,
    },
    props: {
        click: String,
        type: String,
        loading: Boolean,
    },
})
