<template>
    <div class="col-xl-12">
        <div class="card me-3">
            <div class="card-header bg-white p-3">
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="text-info">Liste de point de vente</h5>
                    <router-link to="/point-de-vente/nouveau" class="btn btn-primary"><i class="fa fa-plus me-2"></i>Nouveau
                        point de vente</router-link>
                </div>
            </div>
            <div class="card-body">
                <ListeDepotLoadingComponent v-if="loading" />
                <ListeDepotComponent v-else :depots="getLaravelData(entities)" :entrepot="false" />
            </div>
            <pagination-bootstrap :totalPages="pagination.totalPages" :currentPage="pagination.currentPage"
                @update:currentPage="getPage"></pagination-bootstrap>
        </div>
    </div>
</template>

<script>

import useCRUD from '../../services/CRUDServices';
import ListeDepotComponent from '../../components/depot/ListeDepotComponent.vue';
import ListeDepotLoadingComponent from '../../components/depot/ListeDepotLoadingComponent.vue';
import { defineComponent, onMounted, provide } from 'vue';

import PaginationBootstrap from "../../components/utils/PaginationBootstrap/PaginationBootstrap.vue";
import PaginationBootstrapMixins from "../../components/utils/PaginationBootstrap/PaginationBootstrapMixins.js";
import ParserService from '../../services/ParserService.js';


const { entities, loading, all } = useCRUD('/depot')

export default defineComponent({
    components: {
        ListeDepotComponent, ListeDepotLoadingComponent, PaginationBootstrap
    },

    mixins: [PaginationBootstrapMixins],

    setup() {
        provide('ParserService', ParserService);

        return {
            entities, loading, all,
        }
    },

    data() {
        return {
            page: 1,
        }
    },

    mounted() {
        this.loadData();
    },

    methods: {
        async getPage(page) {
            this.page = page;
        },
        loadData() {
            all(1, JSON.stringify({ page: this.page, perPage: this.pagination.perPage }));
        },
        getLaravelData(response) {
            var pagination = this.getLaravelPagination(response);
            this.pagination.currentPage = pagination.currentPage;
            this.pagination.totalPages = pagination.totalPages;
            return ParserService.getLaravelData(response);
        },
        getLaravelPagination(response) {
            return ParserService.getLaravelPagination(response);
        }
    },

    watch: {
        page() {
            this.loadData();
        }
    }

});

</script>
