<template>
    <div class="col-xl-12">
        <div class="card me-3">
            <div class="card-header bg-white p-3">
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="text-info">Liste de commande fournisseur</h5>
                    <router-link to="/commande/fournisseur/nouveau" class="btn btn-primary"><i
                            class="fa fa-plus me-2"></i>Nouvelle commande</router-link>
                </div>
            </div>
            <div class="card-body">
                <ListeCommandeLoadingComponent v-if="loading" />
                <ListeCommandeComponent v-else :appro="true" :entities="getLaravelData(entities)" />
            </div>
            <pagination-bootstrap :totalPages="pagination.totalPages" :currentPage="pagination.currentPage"
                @update:currentPage="getPage"></pagination-bootstrap>
        </div>
    </div>
</template>

<script>
import { defineComponent, onBeforeMount, provide } from 'vue';
import ListeCommandeComponent from '../../../components/commande/ListeCommandeComponent.vue';
import ListeCommandeLoadingComponent from '../../../components/commande/ListeCommandeLoadingComponent.vue';
import useCRUD from '../../../services/CRUDServices';

import PaginationBootstrap from "../../../components/utils/PaginationBootstrap/PaginationBootstrap.vue";
import PaginationBootstrapMixins from "../../../components/utils/PaginationBootstrap/PaginationBootstrapMixins.js";
import ParserService from '../../../services/ParserService.js';


const { loading, all, entities } = useCRUD('/commandes')

export default defineComponent({
    components: {
        ListeCommandeComponent,
        ListeCommandeLoadingComponent,
        PaginationBootstrap
    },

    mixins: [PaginationBootstrapMixins],

    setup() {
        provide('ParserService', ParserService);

        return {
            entities,
            all,
            loading
        }
    },

    data() {
        return {
            page: 1,
        }
    },

    mounted() {
        this.loadData();
    },

    methods: {
        async getPage(page) {
            this.page = page;
        },
        async loadData() {
            await all(2, JSON.stringify({ page: this.page, perPage: this.pagination.perPage }), true)
        },
        getLaravelData(response) {
            var pagination = this.getLaravelPagination(response);
            this.pagination.currentPage = pagination.currentPage;
            this.pagination.totalPages = pagination.totalPages;
            return ParserService.getLaravelData(response);
        },
        getLaravelPagination(response) {
            return ParserService.getLaravelPagination(response);
        }
    },

    watch: {
        page() {
            this.loadData();
        }
    }

})
</script>
