<template>
  <div class="col-xl-12">
    <div class="card me-3">
      <div class="card-header bg-white p-3">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="text-info">Liste des articles</h5>
          <router-link to="/article/nouveau" class="btn btn-primary"><i class="fa fa-plus me-2"></i>Nouvel
            article</router-link>
        </div>
        <hr />
        <StockFilterComponent @updateParams="loadByFilter" :parentIsReady="parentIsReady"></StockFilterComponent>
      </div>
      <div class="card-body">
        <ListeArticleLoadingComponent v-if="loading" />
        <ListeArticleComponent v-else :articles="getLaravelData(entities)" />
      </div>
      <pagination-bootstrap :totalPages="pagination.totalPages" :currentPage="pagination.currentPage"
        @update:currentPage="getPage"></pagination-bootstrap>
    </div>
  </div>
</template>

<script>
import useCRUD from "../../services/CRUDServices";
import ListeArticleLoadingComponent from "../../components/article/ListeArticleLoadingComponent.vue";
import ListeArticleComponent from "../../components/article/ListeArticleComponent.vue";
import { defineComponent, onBeforeMount, ref } from "vue";

import StockFilterComponent from "../../components/article/filtre/StockFilterComponent.vue";
import PaginationBootstrap from "../../components/utils/PaginationBootstrap/PaginationBootstrap.vue";
import PaginationBootstrapMixins from "../../components/utils/PaginationBootstrap/PaginationBootstrapMixins.js";


const { entities, loading, all } = useCRUD("/article");
const pagination = useCRUD("/article/pagination");

export default defineComponent({
  components: {
    ListeArticleLoadingComponent,
    ListeArticleComponent,
    StockFilterComponent,
    PaginationBootstrap
  },
  mixins: [PaginationBootstrapMixins],
  data() {
    return {
      parentIsReady: false,
      params: {
        filter: {},
        search: '',
        page: 1
      }
    };
  },

  mounted() {
    if (this.$route.query.page) {
      this.params.page = this.$route.query.page;
    }
  },
  setup() {
    onBeforeMount(async () => {
      ref.parentIsReady = true;
    });


    return {
      entities,
      loading,
    };
  },
  methods: {
    async loadByFilter(params) {
      if (params.filter) {
        this.params.filter = params.filter;
      }
      if (params.search) {
        this.params.search = params.search;
      }
    },
    getLaravelData(response) {
      if (response.data) {
        this.pagination.currentPage = response.current_page;
        // this.pagination.perPage = response.per_page;
        this.pagination.totalPages = Math.ceil(parseInt(response.total) / parseInt(response.per_page));
        return response.data;
      };
      return response;
    },
    async getPage(page) {
      this.params.page = page;
    }
  },
  watch: {
    params: {
      async handler() {
        await all(null, JSON.stringify({ filter: this.params.filter, search: this.params.search, page: this.params.page, perPage: this.pagination.perPage }));
      },
      deep: true
    },

  },
});
</script>
