
import { Skeletor } from 'vue-skeletor';
import Flash from '../../functions/Flash';
import useCRUD from '../../services/CRUDServices';
import DeleteBtn from '../../components/html/DeleteBtn.vue';
import { defineComponent } from 'vue';
import VueSimpleAlert from 'vue3-simple-alert';
import Table from '../html/Table.vue';

const { deleting, destroy } = useCRUD("/article")

export default defineComponent({
    props: {
        articles: {
            type: Array<any>,
            required: true,
        }
    },

    components: {
        DeleteBtn,
        Skeletor,
        Table
    },

    setup(props) {
        const deleteItem = async ($event): Promise<any> => {
            await confirmDeletion($event.id, $event.index);
        }

        const confirmDeletion = async (id: number, index: number): Promise<any> => {
            const result = await VueSimpleAlert.fire({
                title: 'Supprimer cet article?',
                text: "Cette action ne peut pas être annulée",
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, supprimer',
                cancelButtonText: 'Non, annuler',
                allowOutsideClick: false,
            });

            if (result.value === true) {
                Flash('loading', "Chargement", "Suppression en cours", 1, false)
                await destroy(id, props.articles, index)
            } else if (result.dismiss) {
                Flash('info', "Message", "Suppression annulée")
            }
        }
        return {
            deleting, confirmDeletion, deleteItem,
        }
    }

});
