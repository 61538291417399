
import store from "../store";
import axios from "axios";
import { computed, onMounted, Ref, ref } from "vue";
import router from "../router/router";

interface Form {
  email: String;
  password: String;
  remember: Boolean | any;
}

interface Errors {
  email: Array<string> | null;
  password: Array<string> | null;
}

export default {
  setup() {
    const form = ref({
      email: "",
      password: "",
      remember: false,
    } as Form);

    const errors = ref({ email: null, password: null } as Errors);
    const errorMessage: Ref<string | null> = ref(null);
    const loading: Ref<Boolean> = ref(false);
    const indicator: Ref<string> = ref("Chargement...");

    /**
     * Permet d'afficher un indicateur de chargement pendant la connexion
     *
     * @return  {string}  L'indicateur
     */
    const loadingIndicator = computed((): string => {
      return loading.value === true ? indicator.value : "";
    });

    /**
     * Permet d'authentifier l'utilisateur
     *
     * @return  {Promise}
     */
    const login = async (): Promise<any> => {
      try {
        loading.value = true;

        await axios.get("/sanctum/csrf-cookie");
        let response = await axios.post("api/auth/login", form.value);

        store.state.user.token =
          /* "51b9a554258d9d4aca0b5832b0317e79963687c8c12bdb7a7a87f083a79da656" */ response.data.token;
        localStorage.setItem("auth_token", response.data.token);

        indicator.value = "Connecté. Redirection en cours...";
        router.push("dashboard");
      } catch (err: any) {
        if (err.response && err.response.data.errors)
          errors.value = err.response.data.errors;
        else errorMessage.value = err.response.data.message;
        loading.value = false;
      }
    };

    onMounted(() => {
      // Associer les evenements pour la necessité de fonctionnement de l'input

      const inputs = document.querySelectorAll(
        ".form-gp input"
      ) as NodeListOf<HTMLInputElement>;
      inputs.forEach((input) => {
        input.addEventListener("focus", () => {
          input.parentElement?.classList.add("focused");
        });

        input.addEventListener("focusout", () => {
          if (input.value.length === 0) {
            input.parentElement?.classList.remove("focused");
          }
        });
      });
    });

    /**
     * Fonction a executer quand l'un de champ change de valeur
     *
     * @param   {string}  key  Nom de l'input
     *
     * @return  {void}
     */
    const input = (key: string): void => {
      if (errors.value[key] && errors.value[key].length > 0)
        errors.value[key] = null;
    };

    return {
      form,
      loading,
      loadingIndicator,
      login,
      errors,
      errorMessage,
      input,
    };
  },
};
