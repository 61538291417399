
import { defineComponent, onBeforeMount } from "vue";

import ListeEtiquetteComponent from "../../components/etiquette/ListeEtiquetteComponent.vue"
import useCRUD from "../../services/CRUDServices";
const { all, entities, loading } = useCRUD('/etiquette');


export default {
    components: { ListeEtiquetteComponent },
    setup() {
        onBeforeMount(async (): Promise<any> => {
            await all(3);
        })

        return {
            loading, entities,
        };
    }
}

