
import router from '../../../router/router';
import useCRUD from '../../../services/CRUDServices';
import { defineComponent, onBeforeMount, ref, Ref } from 'vue';
import CommandeFormComponent from '../../../components/commande/CommandeFormComponent.vue';
import CommandeFormLoadingComponent from '../../../components/commande/CommandeFormLoadingComponent.vue';

const { find, entity, loading } = useCRUD('/commandes')

export default defineComponent({
    components: {
        CommandeFormComponent, CommandeFormLoadingComponent
    },

    setup() {
        const devisId: Ref<number | null> = ref(null);

        onBeforeMount(async (): Promise<any> => {
            const id = router.currentRoute.value.query.devis;

            if (id !== undefined && id !== null) {
                devisId.value = parseInt(id.toString());
            } else {
                devisId.value = null;
            }

            if (devisId.value !== null) {
                // Recuperer le devis en question
                await find(devisId.value);
            } else {
                entity.value = null
            }
        })

        return { entity, loading }
    }
});
