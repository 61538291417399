
import { defineComponent, onBeforeMount } from 'vue';
import router from '../../router/router';
import useCRUD from '../../services/CRUDServices';
import { Skeletor } from 'vue-skeletor';
import BonReceptionFormComponent from '../../components/bon-reception/BonReceptionFormComponent.vue';
import BonReceptionFormLoadingComponent from '../../components/bon-reception/BonReceptionFormLoadingComponent.vue';

const { find, loading, entity } = useCRUD('/commandes'); // Contient tous les fonctions CRUD pour le Commande

export default defineComponent({
    components: {
        Skeletor,
        BonReceptionFormComponent,
        BonReceptionFormLoadingComponent
    },

    setup() {
        onBeforeMount(async () => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            await find(id);
        })

        return {
            loading, entity,
        }
    }
});
