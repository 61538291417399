
import { Skeletor } from 'vue-skeletor';
import useCRUD from '../../../services/CRUDServices';
import DevisFormComponent from '../../../components/devis/DevisFormComponent.vue';
import DevisFormLoadingComponent from '../../../components/devis/DevisFormLoadingComponent.vue';

import { onBeforeMount } from 'vue';
import router from '../../../router/router';

const { find, loading, entity } = useCRUD('/commandes'); // Contient tous les fonctions CRUD pour le Devis

export default {
    components: {
        DevisFormComponent, DevisFormLoadingComponent, Skeletor,
    },
    setup() {
        onBeforeMount(async () => {
            const id = parseInt(router.currentRoute.value.params.id.toString())
            await find(id)
        })

        return {
            loading, entity,
        }
    }
}
