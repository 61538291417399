
import { defineComponent } from "vue";
import { Skeletor } from "vue-skeletor";

export default defineComponent({
    components: {
        Skeletor,
    }
});

