

import { Skeletor } from "vue-skeletor";
import Multiselect from '@vueform/multiselect';
import SaveBtn from '../../components/html/SaveBtn.vue';
import useCRUD from "../../services/CRUDServices";
import { defineComponent } from "vue";

const { update, updating, success } = useCRUD('/depot');

export default defineComponent({
    components: {
        Skeletor, Multiselect, SaveBtn,
    },

    props: {
        depot: {
            type: Object,
            required: true,
        },
        responsables: {
            type: Array<any>,
            required: true,
        }
    },

    setup(props) {
        const save = async (id: number): Promise<any> => {
            const updateType = 1 // Mise a jour de responsable
            await update(id, props.depot, updateType);

            window.scrollTo({ top: 0, behavior: "smooth" });
            success.value = null
        }

        return {
            save, updating,
        };
    },
});

