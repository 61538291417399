import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  action: "",
  method: "post"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-xl-6 mb-3" }
const _hoisted_4 = { class: "col-xl-6 mb-3" }
const _hoisted_5 = { class: "col-xl-12 mb-3" }
const _hoisted_6 = { class: "col-xl-12 mb-3" }
const _hoisted_7 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_toggle_switch = _resolveComponent("toggle-switch")!
  const _component_SaveBtn = _resolveComponent("SaveBtn")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Input, {
          modelValue: _ctx.form.nom,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.nom) = $event)),
          error: _ctx.errors.nom,
          required: true
        }, {
          default: _withCtx(() => [
            _createTextVNode("Nom " + _toDisplayString(_ctx.form.point_vente === true ? "du point de vente" : "de l'entrepot"), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "error"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Input, {
          modelValue: _ctx.form.localisation,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.localisation) = $event)),
          error: _ctx.errors.localisation,
          required: true
        }, {
          default: _withCtx(() => [
            _createTextVNode("Localisation " + _toDisplayString(_ctx.form.point_vente === true ? "du point de vente" : "de l'entrepot"), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "error"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Input, {
          modelValue: _ctx.form.contact,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.contact) = $event)),
          error: _ctx.errors.contact
        }, {
          default: _withCtx(() => [
            _createTextVNode("Contact")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "error"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_toggle_switch, {
          modelValue: _ctx.form.isDefault,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.isDefault) = $event)),
          label: " Par défaut"
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_SaveBtn, {
          onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.save()), ["prevent"])),
          loading: _ctx.creating || _ctx.updating
        }, {
          default: _withCtx(() => [
            _createTextVNode("Enregistrer")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["loading"])
      ])
    ])
  ]))
}