/**
     * Une fonction permet d'extraire les données backend laravel, 
     * surtout quand l'element retourné est un objet contenu l'information sur le pagination laravel 
     */
const getLaravelData = (response) => {
    if (response.data) {
        return response.data;
    };
    return response;
}

const getLaravelPagination = (response) => {
    return {
        currentPage: response.current_page,
        totalPages: Math.ceil(parseInt(response.total) / parseInt(response.per_page))
    }
}

export default {
    getLaravelData,
    getLaravelPagination
}