

import { defineComponent } from 'vue'

type ToggleSwitchComputed = {
    isChecked: boolean,
}

export default defineComponent({
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        label: String,
        inputId: String
    },
    emits: ['update:modelValue'],

    computed: {
        isChecked(): boolean {
            return this.modelValue
        },
    },

    methods: {
        updateCheck(event: any) {
            this.$emit('update:modelValue', event.target.checked)
        },
    },

})
