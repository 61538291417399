export default {
    data() {
        return {
            pagination: {
                currentPage: 0,
                totalPages: 0,
                perPage: 5,
            },
        }
    },
}