
import Input from '../html/Input.vue';
import { Skeletor } from 'vue-skeletor';
import router from '../../router/router';
import Config from '../../config/config';
import SaveBtn from '../html/SaveBtn.vue';
import Flash from '../../functions/Flash';
import MultiSelect from '@vueform/multiselect';
import Datepicker from '@vuepic/vue-datepicker';
import useCRUD from '../../services/CRUDServices';
import { montantHT, montantTTC } from '../../functions/functions';
import ArticleFormComponent from '../article/ArticleFormComponent.vue';
import NouveauClientFormComponent from '../client/ClientFormComponent.vue';
import { computed, onMounted, onBeforeMount, ref, defineComponent } from 'vue';
import NouveauFournisseurComponent from '../fournisseur/FournisseurFormComponent.vue';
import store from '../../store';
import { useRouter, useRoute } from 'vue-router'
import SimpleAlert from 'vue3-simple-alert';
import SearchFromOldDevisComponent from '../devis/SearchFromOldDevisComponent.vue'
const { find, entity, loading } = useCRUD('/commandes')

const Commande = useCRUD('/commandes'); // Contient tous les fonctions CRUD pour le Commande
const Fournisseur = useCRUD('/fournisseur'); // Recuperer le service de CRUD de fournisseur
const Client = useCRUD('/client'); // Recuperer le service de CRUD de client
const Article = useCRUD('/article') // Recuperer le service de CRUD d'article
const Depot = useCRUD('/depot') // Recuperer le service de CRUD de depot

type Form = {
    numero: string | null,
    type: number,
    date: Date | null,
    adresse_livraison: string | null
    validite?: number,
    fournisseur: number | null,
    client: number | null,
    appro: boolean,
    articles: Array<any>,
    devis?: number,
    depot?: number,
}

export default defineComponent({
    name: "CommandeFormComponent",
    components: {
        Input, SaveBtn, Datepicker, MultiSelect, Skeletor, ArticleFormComponent, NouveauFournisseurComponent, NouveauClientFormComponent, SearchFromOldDevisComponent
    },

    props: {
        /**
         * Permet de savoir si on veut modifier un commande ou créer une
         * @values true, false
         */
        nouveau: {
            type: Boolean,
            required: false,
        },

        /**
         * Commande a modifier dans le cas d'une modification
         */
        commande: {
            type: Object,
            required: false,
            default: null,
        },

        /**
         * Permet de determiner si le commande est un approvisionnement ou vente
         * @values true, false
         */
        appro: {
            type: Boolean,
            required: true,
            default: true,
        },

        devis: {
            type: Object,
            required: false,
            default: null,
        },
    },

    setup(props) {

        const router = useRouter()

        const form = ref({
            numero: null,
            type: 2,
            date: null,
            adresse_livraison: null,
            fournisseur: null,
            client: null,
            appro: props.appro,
            articles: [],
        } as Form);

        const nombreArticle = ref(1);
        const creationArticle = ref(false);
        const creationFrs = ref(false);
        const creationClient = ref(false);

        /**
         * Permet de faire un recherche d'articles en fonction de query
         *
         * @param   {string|null}   query  Query de recherche
         *
         * @return  {Promise}
         */
        const fetchArticles = async (query: string | null): Promise<any> => {
            if (query === null) query = ""
            return await Article.findBy('designation', query, 'depot', form.value.depot)
        }

        /**
         * Permet de detecter l'evenement de selection d'un article parmi la liste
         * Permet aussi de calculer le montant
         *
         * @param   {number}   index  Index de l'article
         *
         * @return  {void}
         */
        const handleSelect = (index: number): void => {
            const object = form.value.articles[index].object
            form.value.articles[index].id = object.id
            form.value.articles[index].pu = object.pu
            calculerMontant(index)
        }

        const fetchDepots = async (query: string | null): Promise<any> => {
            if (query === null) query = ""
            return await Depot.findBy('nom', query)
        }

        const articleCree = async (): Promise<any> => {
            await Article.all()
            creationArticle.value = false;
        }

        const creerArticle = (): void => {
            creationArticle.value = !creationArticle.value;
        }

        const frsCree = async (): Promise<any> => {
            await Fournisseur.all()
            creationFrs.value = false;
        }

        const creerFrs = (): void => {
            creationFrs.value = !creationFrs.value;
        }

        const clientCree = async (): Promise<any> => {
            await Client.all()
            creationClient.value = false;
        }

        const creerClient = (): void => {
            creationClient.value = !creationClient.value;
        }

        const confirmSave = async (): Promise<any> => {
            await SimpleAlert.confirm("Voulez-vous enregister cette commande ?", "Enregistrement", "question").then(() => {
                save()
            }).catch((error: undefined) => {
                if (error !== undefined) {
                    Flash('error', "Message d'erreur", "Impossible d'enregister cette commande")
                }
            });
        }

        const save = async () => {
            if (!assujeti.value && props.appro === false) setArticlesTva(0)

            if (props.nouveau === true) {
                await Commande.create(form.value)
                if (Commande.success.value !== null) {
                    router.replace({ query: {} })
                    resetForm()
                    setCommandeKey()
                }
            } else if (props.commande) {
                await Commande.update(props.commande.id, form.value)
            }

            if (Commande.entity.value.id != undefined) router.push({ name: props.appro ? 'commande.fournisseur.voir' : 'commande.client.voir', params: { id: Commande.entity.value.id } })

            window.scrollTo({ top: 0, behavior: 'smooth' })
            Commande.success.value = null
        }

        const setArticlesTva = (tva: number): void => {
            form.value.articles.map(article => {
                article.tva = tva
            })
        }

        const resetForm = () => {
            form.value = {
                numero: null,
                type: 2,
                date: null,
                adresse_livraison: null,
                fournisseur: null,
                client: null,
                appro: props.appro,
                articles: [],
            }
            nombreArticle.value = 1
            generateArticleArray(nombreArticle.value)
        }

        const check = (e: { modelValue: null; }) => {
            if (e.modelValue !== null) Commande.errors.value.fournisseur = null
        }

        const checkArticle = (e: { modelValue: string; }) => {
            if (form.value.articles.length > 1 && e.modelValue !== null) {
                let find = form.value.articles.filter((article: any) => parseInt(article.id) === parseInt(e.modelValue))
                if (find.length > 1) {
                    let i = 0
                    form.value.articles.map((article: any) => {
                        if (parseInt(article.id) === parseInt(e.modelValue)) {
                            if (i === 1) {
                                Flash('danger', "Information", "Cette article existe déja dans votre liste")
                                article.id = null
                            }
                            i++
                        }
                    })
                }
            }
        }

        const checkDate = () => {
            Commande.errors.value.date = null
        }

        const generateArticleArrayFromArticles = (articles: any[]) => {
            articles.forEach((article: null | undefined, index) => {
                addItem(false, article)

                if (props.commande != null && props.commande.prix_vent[index] != undefined && props.commande.prix_vent[index].id != undefined) {
                    form.value.articles[index].object.value = props.commande.prix_vent[index].id
                }
                if (props.devis != null && props.devis.prix_vent[index] != undefined && props.devis.prix_vent[index].id != undefined) {
                    form.value.articles[index].object.value = props.devis.prix_vent[index].id
                }
            })
        }

        const generateArticleArray = (nombreArticle: number) => {
            for (let i = 0; i < nombreArticle; i++) {
                addItem(false)
            }
        }

        const removeItem = (index: number) => {
            form.value.articles.splice(index, 1)
            nombreArticle.value--
        }

        const addItem = (increment: boolean = true, article: any = null) => {
            if (nombreArticle.value > Config.commande.MAX_ARTICLE) {
                Flash('error', "Message d'erreur", `Nombre d'article maximum atteint. Limite ${Config.commande.MAX_ARTICLE}`)
                return
            }

            if (article === null) {
                form.value.articles.push({
                    id: null,
                    quantite: 1,
                    pu: null,
                    tva: 20.00,
                    montant_ht: null,
                    montant_ttc: null,
                })
            } else {
                form.value.articles.push({
                    id: article.id,
                    quantite: article.pivot.quantite,
                    pu: article.pivot.pu,
                    tva: article.pivot.tva,
                    montant_ht: montantHT(article),
                    montant_ttc: montantTTC(article),
                    ...(props.appro === false ? {
                        object: {
                            id: article.id,
                            value: article.pivot.reference_id,
                            reference: article.reference,
                            designation: article.designation,
                            quantite: article.pivot.quantite,
                            pu: article.pivot.pu,
                            label: `${article.reference} - ${article.designation} - ${article.pivot.pu}`
                        }
                    } : {}),
                })
            }

            if (increment === true) nombreArticle.value++
        }


        /**
         * Permet de calculer le montant pour une ligne de l'article
         *
         * @param {Number}  index   Index de la ligne darticle
         */
        const calculerMontant = (index: string | number) => {
            let pu = form.value.articles[index].pu
            let quantite = form.value.articles[index].quantite
            let tva = form.value.articles[index].tva

            if (pu < 0) form.value.articles[index].pu = Math.abs(pu)
            if (quantite < 0) form.value.articles[index].quantite = Math.abs(quantite)
            if (tva < 0) form.value.articles[index].tva = Math.abs(tva)

            if (assujeti.value === false && props.appro == false) tva = 0;


            let montant_ht = Math.round((Math.abs(quantite) * Math.abs(pu)) * 100) / 100
            let montant_ttc = Math.round((montant_ht + (montant_ht * Math.abs(tva) / 100)) * 100) / 100

            form.value.articles[index].montant_ht = montant_ht
            form.value.articles[index].montant_ttc = montant_ttc
        }


        /**
         * Recuperer la nouvelle numéro du devis et le mettre dans la formulaire
         *
         * @return  {Promise}
         */
        const setCommandeKey = async (): Promise<any> => {
            await Commande.getKey(2, form.value.appro)
            form.value.numero = Commande.key.value
        }

        const hasError = computed(() => {
            if (Commande.errors.value.fournisseur && Commande.errors.value.fournisseur.length > 0 && form.value.appro === true) return true
            if (Commande.errors.value.client && Commande.errors.value.client.length > 0 && form.value.appro === false) return true
            return false
        })

        const dateState = computed(() => {
            if (Commande.errors.value.date && Commande.errors.value.date.length > 0) return false
            return null
        })


        /**
         * Permet de savoiir si on veut recharger les données pendant le chargement
         */
        const loaded = ref(false)

        onMounted(() => {
            //if (props.appro === true) Article.all();
            Article.all();
            Fournisseur.all();
            Client.all();
            Depot.all(3)
            if (props.nouveau === true) setCommandeKey();

            loaded.value = false
        })

        onBeforeMount(() => {
            // Si c'est un modification de commande
            if (props.nouveau === false && props.commande) {
                nombreArticle.value = props.commande.articles.length
                form.value.numero = props.commande.numero
                form.value.date = props.commande.date
                form.value.adresse_livraison = props.commande.adresse_livraison
                form.value.fournisseur = props.commande.fournisseur
                form.value.client = props.commande.client
                form.value.type = 2
                form.value.appro = props.appro
                form.value.depot = props.commande.depot ?? null

                generateArticleArrayFromArticles(props.commande.articles)
            }

            // Si création d'une commande a partir d'un devis
            else if (props.devis !== null && props.devis.id !== undefined) {
                nombreArticle.value = props.devis.articles.length
                form.value.date = props.devis.date
                form.value.adresse_livraison = props.devis.adresse_livraison
                form.value.fournisseur = props.devis.fournisseur
                form.value.client = props.devis.client
                form.value.type = 2
                form.value.appro = props.appro
                form.value.devis = props.devis.id
                form.value.depot = props.devis.depot

                generateArticleArrayFromArticles(props.devis.articles)
            }

            // Création d'une commande a partir de rien
            else {
                form.value.appro = props.appro;
                generateArticleArray(nombreArticle.value);
            }

            loaded.value = true
        })


        /**
         * Permet de determiner si on doit charger le select durant le chargement
         *
         * @return  {boolean}
         */
        const resolveOnLoad = computed((): boolean => {
            return (loaded.value === true && ((props.devis && props.devis.id !== null) || (props.commande && props.commande.id !== null)))
        })

        const assujeti = computed((): boolean => {
            return store.getters.isAssujeti
        })

        /**
         * Fonction pour generer un tableau d'article à partir des articles en provenance d'un ancien devis
         *
         *
         */

        const genereateArticleFromOld = async (id) => {
            await find(id)

            entity.value.id = undefined

            if (props.appro == false) {
                form.value.depot = entity.value.depot
            }
            nombreArticle.value = 0
            form.value.articles = []
            addItem()
            entity.value.articles.forEach((article, index) => {
                if (index < (entity.value.articles.length - 1)) addItem()
                form.value.articles[index].id = article.id
                form.value.articles[index].quantite = article.pivot.quantite
                form.value.articles[index].pu = article.pivot.pu
                form.value.articles[index].tva = article.pivot.tva

                if (props.appro == false) form.value.articles[index].object = {
                    id: article.id,
                    value: article.id,
                    reference: article.reference,
                    designation: article.designation,
                    quantite: article.pivot.quantite,
                    pu: article.pivot.pu,
                    label: `${article.reference} - ${article.designation} - ${article.pivot.pu}`
                }

                calculerMontant(index)

            })

        }

        return {
            Commande, Fournisseur, Client, Article, creationFrs, hasError, dateState, creationClient, form, nombreArticle, creationArticle, resolveOnLoad, assujeti, Depot,
            Flash, creerArticle, articleCree, frsCree, creerFrs, checkArticle, setCommandeKey, calculerMontant, addItem, removeItem, generateArticleArray,
            generateArticleArrayFromArticles, checkDate, check, save, clientCree, creerClient, fetchDepots, fetchArticles, handleSelect, confirmSave, genereateArticleFromOld
        }
    },
})

