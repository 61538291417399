
import Input from "../html/Input.vue";
import SaveBtn from "../html/SaveBtn.vue";
import Alert from "../html/Alert.vue";
import MultiSelect from "@vueform/multiselect";
import useCRUD from "../../services/CRUDServices";
import { ref, computed, onMounted, defineComponent, Ref } from "vue";
import { Skeletor } from "vue-skeletor";
import CategorieFormComponent from "../categorie/CategorieFormComponent.vue";

const Fournisseur = useCRUD("/fournisseur"); // Contient tous les fonctions CRUD pour le Fournisseur
const Categorie = useCRUD("/categorie"); // Contient tous les foncions CRUD pour le categorie

interface Form {
  nom: string | null;
  adresse: string | null;
  email: string | null;
  contact: string | null;
  categories: Array<any>;
  nif: string | null;
  cif: string | null;
  stat: string | null;
  description: string | null;
}

export default defineComponent({
  components: {
    Input,
    SaveBtn,
    Alert,
    MultiSelect,
    Skeletor,
    CategorieFormComponent,
  },

  props: {
    nouveau: {
      type: Boolean,
      required: false,
      default: true,
    },
    fournisseur: {
      type: Object,
      required: false,
      default: null,
    },
  },

  emits: ["frs-cree"],

  setup(props, { emit }) {
    const form = ref({
      nom: null,
      adresse: null,
      email: null,
      contact: null,
      categories: [],
      nif: null,
      cif: null,
      stat: null,
      description: null,
    } as Form);

    const creationCategorie: Ref<boolean> = ref(false);

    const save = async (): Promise<any> => {
      if (props.nouveau === true) {
        await Fournisseur.create(form.value);
      } else {
        const id = props.fournisseur.id;
        await Fournisseur.update(id, form.value);
      }

      window.scrollTo({ top: 0, behavior: "smooth" });
      if (Fournisseur.success.value !== null && props.nouveau === true) {
        resetForm();
        emit("frs-cree");
      }
      Fournisseur.success.value = null;
    };

    const categorieCree = async (): Promise<any> => {
      creationCategorie.value = false;
      await Categorie.all(2);
    };

    const creerCategorie = () => {
      creationCategorie.value = !creationCategorie.value;
    };

    const resetForm = () => {
      form.value = {
        nom: null,
        adresse: null,
        email: null,
        contact: null,
        categories: [],
        nif: null,
        cif: null,
        stat: null,
        description: null,
      };
    };

    const check = (e: { modelValue: string | any[] }) => {
      if (e.modelValue.length > 0) Fournisseur.errors.value.categories = null;
    };

    const hasError = computed((): boolean => {
      if (
        Fournisseur.errors.value.categories &&
        Fournisseur.errors.value.categories.length > 0
      )
        return true;
      return false;
    });

    onMounted(async (): Promise<any> => {
      if (props.nouveau === false) {
        form.value = {
          nom: props.fournisseur.nom,
          adresse: props.fournisseur.adresse,
          email: props.fournisseur.email,
          contact: props.fournisseur.contact,
          categories: props.fournisseur.categories,
          nif: props.fournisseur.nif,
          cif: props.fournisseur.cif,
          stat: props.fournisseur.stat,
          description: props.fournisseur.description,
        };
      }
      await Categorie.all(2);
    });

    return {
      Fournisseur,
      Categorie,
      form,
      save,
      resetForm,
      check,
      hasError,
      categorieCree,
      creerCategorie,
      creationCategorie,
    };
  },
});
