

import Flash from '../../functions/Flash';
import useCRUD from '../../services/CRUDServices';
import DeleteBtn from '../../components/html/DeleteBtn.vue';
import SimpleAlert from 'vue3-simple-alert';
import { computed, defineComponent } from 'vue';

const { deleting, destroy } = useCRUD('/etiquette');

export default defineComponent({
    props: {
        etiquettes: {
            type: Array<any>,
            required: true
        }
    },
    setup(props) {
        const confirmDeletion = async (id: number, index: number): Promise<any> => {
            await SimpleAlert.confirm("Voulez-vous supprimer ce étiquettes ?", "Question", "question").then(() => {
                Flash('loading', "Chargement", "Suppression en cours", 1, false)
                destroy(id, props.etiquettes, index)
            }).catch(error => {
                if (error !== undefined) {
                    Flash('error', "Message d'erreur", "Impossible de supprimer ce étiquette")
                }
            });
        }

        return {
            deleting, confirmDeletion,
        }
    },
    components: {
        DeleteBtn,
    },
})

