<template>
    <div class="col-xl-12">
        <div class="card me-3">
            <div class="card-header bg-white p-3">
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="text-info">Liste de catégorie de client</h5>
                    <router-link to="/client/categorie/nouveau" class="btn btn-primary"><i
                            class="fa fa-plus me-2"></i>Nouvelle catégorie</router-link>
                </div>
            </div>
            <div class="card-body">
                <ListeCategorieLoadingComponent v-if="loading" />
                <ListeCategorieComponent v-else :categories="getLaravelData(entities)" :type="1" />
            </div>
            <pagination-bootstrap :totalPages="pagination.totalPages" :currentPage="pagination.currentPage"
                @update:currentPage="getPage"></pagination-bootstrap>
        </div>
    </div>
</template>

<script>
import useCRUD from "../../../services/CRUDServices";
import { defineComponent, onBeforeMount, provide } from "vue";
import ListeCategorieComponent from "../../../components/categorie/ListeCategorieComponent.vue";
import ListeCategorieLoadingComponent from "../../../components/categorie/ListeCategorieLoadingComponent.vue";

import PaginationBootstrap from "../../../components/utils/PaginationBootstrap/PaginationBootstrap.vue";
import PaginationBootstrapMixins from "../../../components/utils/PaginationBootstrap/PaginationBootstrapMixins.js";
import ParserService from '../../../services/ParserService.js';


const { entities, loading, all } = useCRUD('/categorie');

export default defineComponent({
    components: { ListeCategorieComponent, ListeCategorieLoadingComponent, PaginationBootstrap },

    mixins: [PaginationBootstrapMixins],

    setup() {
        provide('ParserService', ParserService);

        return {
            entities, loading, all,
        }
    },

    data() {
        return {
            page: 1,
        }
    },

    mounted() {
        this.loadData();
    },

    methods: {
        async getPage(page) {
            this.page = page;
        },
        loadData() {
            all(1, JSON.stringify({ page: this.page, perPage: this.pagination.perPage }));
        },
        getLaravelData(response) {
            var pagination = this.getLaravelPagination(response);
            this.pagination.currentPage = pagination.currentPage;
            this.pagination.totalPages = pagination.totalPages;
            return ParserService.getLaravelData(response);
        },
        getLaravelPagination(response) {
            return ParserService.getLaravelPagination(response);
        }
    },

    watch: {
        page() {
            this.loadData();
        }
    }
});

</script>
