
import { defineComponent, Ref, ref, watch } from "vue";
import DeleteBtn from "./DeleteBtn.vue";
import { Skeletor } from "vue-skeletor";
import { format } from "../../functions/functions";

export default defineComponent({
  props: {
    columns: {
      type: [Array<string>, Object],
      required: false,
      default: null,
    },
    data: {
      type: Array<any>,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    actions: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },
    ordered: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    casts: {
      type: Object,
      required: false,
      default: [],
    },
    showable: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    deletable: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: false,
      default: "id",
    },
  },
  updated() {
    console.log("data", this.d);
  },

  emits: ["onShow", "onDeleteItem"], // <--- add this line

  setup(props) {
    const cols: Ref<string[]> = ref([]);
    const alias: Ref<string[]> = ref([]);
    const query: Ref<string> = ref("");
    const d: Ref<Array<any>> = ref(props.data);

    if (props.columns === null) {
      cols.value = Object.keys(props.data[0]);
      alias.value = cols.value;
    } else {
      if (Array.isArray(props.columns)) cols.value = props.columns;
      if (typeof props.columns === "object") {
        alias.value = Object.values(props.columns);
        cols.value = Object.keys(props.columns);
      }
    }

    watch(
      () => props.loading,
      (value) => {
        if (value === false) d.value = props.data;
      }
    );

    const changeOrder = (col: string) => {};

    /**
     * Retourner la valeur a afficher dans la colonne
     *
     * @param   {any}     row  La ligne en question (Un enregistrement dans l'objet) - Peut être un tableau complexe
     * @param   {string}  col  La colonne en question (Un clé dans row)
     *
     * @return  {string}           [return description]
     */
    const display = (
      row: { [x: string]: any },
      col: string
    ): string | Array<any> => {
      if (row[col] === null) return "Undefined value";
      else if (row[col] !== undefined) {
        if (props.casts[col] !== undefined) {
          return castingValue(props.casts[col], row[col]);
        }
        return row[col];
      } else {
        let columns: string[] = [];
        let operator: null | string = null;

        if (col.includes("-")) {
          columns = col.split("-");
          operator = "minus";
        } else {
          columns = col.split(".");
        }

        const first = columns[0].trim();
        columns = columns.slice(1);

        let data = row[first];

        columns.forEach((column: string) => {
          if (operator === "minus") {
            data = parseFloat(data);
            if (isNaN(data)) {
              data = null;
            }

            if (row[column.trim()] !== null)
              data -= parseFloat(row[column.trim()]);
          } else {
            data = data[column.trim()];
          }
        });

        if (data === null || data === undefined) return "Undefined value";

        if (props.casts[col] !== undefined) {
          return castingValue(props.casts[col], data);
        }

        return data;
      }
    };

    const castingValue = (castType: string, value: string | number): string => {
      if (castType === "decimal") return (value + " - " + castType).toString();
      if (castType === "money")
        if (typeof value === "number") return format(value).toString();
        else return format(parseFloat(value)).toString();
      return value.toString();
    };

    const filter = () => {
      if (query.value === "") d.value = props.data;
      else {
        d.value = d.value.filter((data) => {
          return data.designation.toLowerCase().includes(query.value);
        });
      }
    };

    return {
      d,
      cols,
      alias,
      query,
      filter,
      format,
      display,
      castingValue,
      changeOrder,
    };
  },
  components: { DeleteBtn, Skeletor },
});
