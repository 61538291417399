
import { Skeletor } from 'vue-skeletor';
import { defineComponent } from 'vue';

export default defineComponent({

    components: {
        Skeletor,
    },

})
