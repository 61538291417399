import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "table table-striped table-hover" }
const _hoisted_2 = { class: "bg-secondary text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeletor = _resolveComponent("Skeletor")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", _hoisted_2, [
      _createElementVNode("tr", null, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (i) => {
          return _createElementVNode("th", { key: i }, [
            _createVNode(_component_Skeletor, {
              height: "30",
              width: "100%",
              style: {"border-radius":"3px"}
            })
          ])
        }), 64 /* STABLE_FRAGMENT */))
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
        return _createElementVNode("tr", { key: i }, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (j) => {
            return _createElementVNode("td", { key: j }, [
              _createVNode(_component_Skeletor, {
                height: "30",
                width: "100%",
                style: {"border-radius":"3px"}
              })
            ])
          }), 64 /* STABLE_FRAGMENT */))
        ])
      }), 64 /* STABLE_FRAGMENT */))
    ])
  ]))
}