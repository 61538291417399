
import useCRUD from '../../services/CRUDServices'
import router from '../../router/router';

import { defineComponent, onBeforeMount } from 'vue'
import TransfertForm from '../../components/transfert-article/TransfertFormComponent.vue'
const { entity, find, loading } = useCRUD('/transfert-article'); // Contient tous les fonctions CRUD pour les transferts



export default defineComponent({
    components: {TransfertForm},
    setup(){
        onBeforeMount(async () => {
            const id = parseInt(router.currentRoute.value.params.id.toString())
            await find(id)
        })

        return {
            entity, find, loading,
        }
    }
});

