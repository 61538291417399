
import ProfileAvatar from "vue-profile-avatar";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  components: {
    ProfileAvatar,
  },
});
