

import { onBeforeMount } from 'vue'
import router from '../../../router/router'
import useCRUD from '../../../services/CRUDServices'
import VoirDevisComponent from '../../../components/devis/VoirDevisComponent.vue'
import VoirDevisLoadingComponent from '../../../components/devis/VoirDevisLoadingComponent.vue'

const { find, loading, entity } = useCRUD('/commandes')

export default {
    components: {
        VoirDevisComponent,
        VoirDevisLoadingComponent
    },

    setup() {
        onBeforeMount(async (): Promise<any> => {
            const id = parseInt(router.currentRoute.value.params.id.toString())
            await find(id)
        })

        return {
            entity, loading,
        }
    }

}

