import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  action: "",
  method: "post"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-xl-6 mb-3" }
const _hoisted_4 = { class: "col-xl-6 mb-3" }
const _hoisted_5 = { class: "col-xl-3 mb-3" }
const _hoisted_6 = { class: "col-xl-3 mb-3" }
const _hoisted_7 = { class: "col-xl-6 mb-3" }
const _hoisted_8 = {
  for: "categorie",
  class: "form-label"
}
const _hoisted_9 = {
  key: 2,
  class: "text-danger mt-1"
}
const _hoisted_10 = {
  key: 0,
  class: "col-xl-12 border border-secondary shadow p-5 mb-5 mt-3"
}
const _hoisted_11 = { class: "col-xl-3 mb-3" }
const _hoisted_12 = { class: "col-xl-3 mb-3" }
const _hoisted_13 = { class: "col-xl-6 mb-3" }
const _hoisted_14 = { class: "col-xl-12 mb-3" }
const _hoisted_15 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Skeletor = _resolveComponent("Skeletor")!
  const _component_CategorieFormComponent = _resolveComponent("CategorieFormComponent")!
  const _component_SaveBtn = _resolveComponent("SaveBtn")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Input, {
          modelValue: _ctx.form.nom,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.nom) = $event)),
          error: _ctx.Fournisseur.errors.value.nom,
          required: true
        }, {
          default: _withCtx(() => [
            _createTextVNode("Nom du fournisseur")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "error"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Input, {
          modelValue: _ctx.form.adresse,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.adresse) = $event)),
          error: _ctx.Fournisseur.errors.value.adresse,
          required: true
        }, {
          default: _withCtx(() => [
            _createTextVNode("Adresse")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "error"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Input, {
          modelValue: _ctx.form.email,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.email) = $event)),
          error: _ctx.Fournisseur.errors.value.email,
          required: false
        }, {
          default: _withCtx(() => [
            _createTextVNode("Email")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "error"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Input, {
          modelValue: _ctx.form.contact,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.contact) = $event)),
          error: _ctx.Fournisseur.errors.value.contact,
          required: true
        }, {
          default: _withCtx(() => [
            _createTextVNode("Contact")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "error"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("label", _hoisted_8, [
          _createTextVNode("Catégorie ("),
          (_ctx.creationCategorie)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: "#",
                onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.creerCategorie && _ctx.creerCategorie(...args)), ["prevent"])),
                class: "text-danger"
              }, "Fermer"))
            : (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: "#",
                onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.creerCategorie && _ctx.creerCategorie(...args)), ["prevent"])),
                class: "text-primary"
              }, "Créer nouveau")),
          _createTextVNode(")")
        ]),
        (!_ctx.Categorie.loading.value)
          ? (_openBlock(), _createBlock(_component_MultiSelect, {
              key: 0,
              class: _normalizeClass(_ctx.hasError ? 'border-danger' : ''),
              object: _ctx.nouveau === false ? true : false,
              label: "libelle",
              valueProp: "id",
              multiple: true,
              modelValue: _ctx.form.categories,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.categories) = $event)),
              options: _ctx.Categorie.entities.value,
              mode: "tags",
              closeOnSelect: false,
              clearOnSelect: false,
              searchable: true,
              noOptionsText: "Aucune catégorie",
              noResultsText: "Aucune catégorie",
              onClose: _ctx.check
            }, null, 8 /* PROPS */, ["class", "object", "modelValue", "options", "onClose"]))
          : (_openBlock(), _createBlock(_component_Skeletor, {
              key: 1,
              height: "40",
              width: "100%",
              style: {"border-radius":"3px"}
            })),
        (_ctx.hasError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.Fournisseur.errors.value.categories[0]), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]),
      (_ctx.creationCategorie)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_CategorieFormComponent, {
              onCategorieCree: _ctx.categorieCree,
              nouveau: true,
              type: 2
            }, null, 8 /* PROPS */, ["onCategorieCree"])
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_Input, {
          modelValue: _ctx.form.nif,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.nif) = $event)),
          error: _ctx.Fournisseur.errors.value.nif,
          required: false
        }, {
          default: _withCtx(() => [
            _createTextVNode("N° SIRET")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "error"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_Input, {
          modelValue: _ctx.form.cif,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.cif) = $event)),
          error: _ctx.Fournisseur.errors.value.cif,
          required: false
        }, {
          default: _withCtx(() => [
            _createTextVNode("CIF")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "error"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_Input, {
          modelValue: _ctx.form.stat,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.stat) = $event)),
          error: _ctx.Fournisseur.errors.value.stat,
          required: false
        }, {
          default: _withCtx(() => [
            _createTextVNode("TVA INTRACOM")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "error"])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_Input, {
          modelValue: _ctx.form.description,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.description) = $event)),
          error: _ctx.Fournisseur.errors.value.description,
          type: "textarea",
          required: false
        }, {
          default: _withCtx(() => [
            _createTextVNode("Description")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "error"])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_SaveBtn, {
          onClick: _withModifiers(_ctx.save, ["prevent"]),
          loading: _ctx.Fournisseur.creating.value
        }, {
          default: _withCtx(() => [
            _createTextVNode("Enregistrer")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick", "loading"])
      ])
    ])
  ]))
}