

import { onBeforeMount } from 'vue';
import router from '../../../router/router';
import useCRUD from '../../../services/CRUDServices';
import VoirCommandeComponent from '../../../components/commande/VoirCommandeComponent.vue';
import VoirCommandeLoadingComponent from '../../../components/commande/VoirCommandeLoadingComponent.vue';

const { loading, find, entity } = useCRUD('/commandes');

export default {
    setup() {

        onBeforeMount(async () => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            await find(id)
        })

        return {
            loading, entity,
        }
    },

    components: {
        VoirCommandeComponent,
        VoirCommandeLoadingComponent
    },

}
