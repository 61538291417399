

import { Skeletor } from 'vue-skeletor'

export default {

    components: {
        Skeletor,
    }

}

