<template>
  <div class="row">
    <div class="col-md-6 d-flex flex-column">
      <toggle-switch label="&nbsp;Article en stock" v-model="filter.available_stock"></toggle-switch>
      <toggle-switch label="&nbsp;Article en alerte de stock uniquement" v-model="filter.stock_alert"></toggle-switch>
      <SelectInputWithSelectedElement :options="etiquettes" @changeSelectedElement="setSelected">
      </SelectInputWithSelectedElement>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <Input placeholder="Recherche..." id="search" v-model.trim="search">
        Recherche
        </Input>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { defineComponent, onBeforeMount } from "vue";
import ToggleSwitch from "../../html/ToggleSwitch.vue";

import SelectInputWithSelectedElement from "../../html/select/SelectInputWithSelectedElement.vue";
import Input from "../../html/Input.vue";

import useCRUD from "../../../services/CRUDServices";
import { getCurrentInstance } from "@vue/runtime-core";

// const { entities, loading, all } = useCRUD("/etiquette")
const { entities, loading, all } = useCRUD("/etiquette");

export default defineComponent({
  components: {
    ToggleSwitch,
    SelectInputWithSelectedElement,
    Input,
  },
  mounted() {
    this.$emit("updateParams", this.filter);
  },
  props: {
    parentIsReady: { default: false, type: Boolean },
  },
  data() {
    return {
      etiquetteDefaultValue: [{ value: null, label: "-- Etiquette --" }],
      filter: {
        stock_alert: false,
        available_stock: true,
        etiquettes: [],
      },
      search: "",
      etiquettes: [{ value: null, label: "-- Etiquette --" }],
      searchTimeoutId: 0,
    };
  },
  setup() {
    var waiting = false;
    const urlParams = new URLSearchParams(window.location.search);
    onBeforeMount(async () => {
      waiting = await all();
    });

    if (waiting != false) {
      if (urlParams.has("etiquettes") && urlParams.get("etiquettes") != "") {
        var etiquettes = urlParams.get("etiquettes").split(",");
        etiquettes.forEach(etiquette_url_value => {
          this.etiquettes.forEach(etiquette_data => {
            if (etiquette_url_value == etiquette_data.value) this.filter.etiquettes.push(etiquette_url_value);
          });
        });
      }
    }

    return {
      entities,
      loading,
    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("search")) {
      this.search = urlParams.get("search");
    }
    if (urlParams.has("stock_alert")) {
      this.filter.stock_alert = urlParams.get("stock_alert") == "true";
    }
    if (urlParams.has("available_stock")) {
      this.filter.available_stock = urlParams.get("available_stock") == "true";
    }
    if (urlParams.has("etiquettes") && urlParams.get("etiquettes") != "") {
      this.filter.etiquettes = urlParams.get("etiquettes").split(",");
    }

    this.$emit("updateParams", { filter: this.filter, search: this.search });

  },
  beforeRouteEnter(to, from, next) {
    const urlParams = new URLSearchParams(to.query);
    const filter = {
      stock_alert: false,
      available_stock: true,
      etiquettes: [],
    };
    let search = "";

    if (urlParams.has("search")) {
      this.search = urlParams.get("search");
    }
    if (urlParams.has("stock_alert")) {
      this.filter.stock_alert = urlParams.get("stock_alert") === "true";
    }
    if (urlParams.has("available_stock")) {
      this.filter.available_stock = urlParams.get("available_stock") === "true";
    }
    // if (urlParams.has("etiquettes") && urlParams.get("etiquettes") != "") {
    //   var etiquettes = urlParams.get("etiquettes").split(",");
    //   etiquettes.forEach(etiquette_url_value => {
    //     this.etiquettes.forEach(etiquette_data => {
    //       if (etiquette_url_value == etiquette_data.value) this.filter.etiquettes.push(etiquette_url_value);
    //     });
    //   });
    // }

    this.$emit("updateParams", { filter: this.filter, search: this.search });

    // définir les valeurs de données
    next(vm => {
      vm.filter = filter;
      vm.search = search;
    });
  },
  computed: {
  },
  methods: {
    setSelected(value) {
      while (this.filter.etiquettes.pop());
      value.forEach((v) => {
        this.filter.etiquettes.push(v);
      });
    },
  },
  watch: {
    filter: {
      handler() {
        // construire l'URL GET en utilisant les valeurs des variables
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set('search', this.search);
        urlSearchParams.set('stock_alert', this.filter.stock_alert);
        urlSearchParams.set('available_stock', this.filter.available_stock);
        urlSearchParams.set('etiquettes', this.filter.etiquettes.join(","));
        const urlParams = '?' + urlSearchParams.toString();
        const url = `${window.location.pathname}${urlParams}`;

        // naviguer vers l'URL GET
        this.$router.replace(url);

        this.$emit("updateParams", { filter: this.filter, search: this.search });

      },
      deep: true,
    },
    search: {
      handler() {
        // construire l'URL GET en utilisant les valeurs des variables
        const urlParams = new URLSearchParams({
          search: this.search,
          stock_alert: this.filter.stock_alert,
          available_stock: this.filter.available_stock,
          etiquettes: this.filter.etiquettes.join(","),
        });
        const url = `${window.location.pathname}?${urlParams.toString()}`;

        // naviguer vers l'URL GET
        this.$router.replace(url);

        clearTimeout(this.searchTimeoutId);
        this.searchTimeoutId = setTimeout(() => {
          this.$emit("updateParams", { filter: this.filter, search: this.search });
        }, 1000);
      },
    },
    parentIsReady() {
      this.$emit("updateParams", { filter: this.filter, search: this.search });
    },
    loading() {
      var e = "";
      for (e in entities) {
        if (e == "_rawValue") {
          entities[e].forEach((entitie) => {
            this.etiquettes.push({
              label: entitie.libelle,
              value: entitie.id,
            });
          });
        }
      }
    },
  },

});
</script>
