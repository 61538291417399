
import store from '../../store';

import { formatDate, format, modeLivraison, chargeLivraison } from '../../functions/functions';

export default {
    props: {
        bonLivraison: {
            type: Object,
            required: true,
        },
    },

    setup(props) {
        const infoEntreprise = store.state.infoEntreprise


        const reliquat = (article_id) => {
            let ordered = props.bonLivraison.get_commande.articles.filter((value) => value.id == article_id)[0]
            let delivered = props.bonLivraison.get_articles.filter((value) => value.article == article_id)[0]

            return parseFloat(ordered.pivot.quantite) - delivered.delivred
        }


        return {
            formatDate, format, modeLivraison, chargeLivraison, infoEntreprise, reliquat
        }
    },

}
