

import useCRUD from '../../services/CRUDServices';
import ClientFormComponent from '../../components/client/ClientFormComponent.vue';
import ClientFormLoadingComponent from '../../components/client/ClientFormLoadingComponent.vue';
import { defineComponent, onMounted } from 'vue';
import router from '../../router/router';

const Client = useCRUD('/client')

export default defineComponent({
    components: {
        ClientFormComponent, ClientFormLoadingComponent,
    },

    setup() {
        onMounted(async () => {
            const id = parseInt(router.currentRoute.value.params.id.toString())
            await Client.find(id)
        })

        return {
            Client,
        }
    },

});
