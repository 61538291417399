<template>
    <div class="col-xl-12">
        <div class="card me-3">
            <div class="card-header bg-white p-3">
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="text-info">Liste des fournisseurs</h5>
                    <router-link to="/fournisseur/nouveau" class="btn btn-primary"><i class="fa fa-plus me-2"></i>Nouveau
                        fournisseur</router-link>
                </div>
            </div>
            <div class="card-body">
                <ListeFournisseurLoadingComponent v-if="loading" />
                <ListeFournisseurComponent v-else :fournisseurs="getLaravelData(entities)" />
            </div>
            <pagination-bootstrap :totalPages="pagination.totalPages" :currentPage="pagination.currentPage"
                @update:currentPage="getPage"></pagination-bootstrap>
        </div>
    </div>
</template>

<script>
import ListeFournisseurLoadingComponent from "../../components/fournisseur/ListeFournisseurLoadingComponent.vue";
import ListeFournisseurComponent from "../../components/fournisseur/ListeFournisseurComponent.vue";
import { defineComponent, onBeforeMount, provide } from "vue";
import useCRUD from "../../services/CRUDServices";

import PaginationBootstrap from "../../components/utils/PaginationBootstrap/PaginationBootstrap.vue";
import PaginationBootstrapMixins from "../../components/utils/PaginationBootstrap/PaginationBootstrapMixins.js";
import ParserService from '../../services/ParserService.js';

const { loading, entities, all } = useCRUD('/fournisseur');

export default defineComponent({
    components: { ListeFournisseurComponent, ListeFournisseurLoadingComponent, PaginationBootstrap },


    mixins: [PaginationBootstrapMixins],

    setup() {
        provide('ParserService', ParserService);

        return {
            entities, loading, all,
        }
    },

    data() {
        return {
            page: 1,
        }
    },

    mounted() {
        this.loadData();
    },

    methods: {
        async getPage(page) {
            this.page = page;
        },
        loadData() {
            all(2, JSON.stringify({ page: this.page, perPage: this.pagination.perPage }));
        },
        getLaravelData(response) {
            var pagination = this.getLaravelPagination(response);
            this.pagination.currentPage = pagination.currentPage;
            this.pagination.totalPages = pagination.totalPages;
            return ParserService.getLaravelData(response);
        },
        getLaravelPagination(response) {
            return ParserService.getLaravelPagination(response);
        }
    },

    watch: {
        page() {
            this.loadData();
        }
    }
});

</script>
