
import Input from '../html/Input.vue';
import { Skeletor } from 'vue-skeletor';
import router from '../../router/router';
import Config from '../../config/config';
import SaveBtn from '../html/SaveBtn.vue';
import Flash from '../../functions/Flash';
import MultiSelect from '@vueform/multiselect';
import Datepicker from '@vuepic/vue-datepicker';
import useCRUD from '../../services/CRUDServices';
import { computed, onMounted, onBeforeMount, ref, defineComponent, Ref, watch } from 'vue';

const Transfert = useCRUD('/transfert-article'); // Contient tous les fonctions CRUD pour les transferts
const Depot = useCRUD('/depot'); // Contient tous les fonctions CRUD pour le depôt
const Article = useCRUD('/article') // Recuperer le service de CRUD de l'article


type Form = {
    numero: string | null,
    date: Date | null,
    validite?: number,
    articles: Array<any>,
    livreur: string | null,
    depotOrigin: number | null,
    depotDestiny: number | null,
    appro: boolean,

}


export default defineComponent({
    props: {
        nouveau: {
            type: Boolean,
            required: false,
            default: true,
        },
        appro: {
            type: Boolean,
            required: true,
            default: true,
        },
        transfert: {
            type: Object,
            required: false,
            default: {}
        },

    },

    components: {
        Input, SaveBtn, Datepicker, MultiSelect, Skeletor,
    },
    setup(props) {
        let form = ref({
            numero: null,
            date: null,
            articles: [],
            livreur: null,
            depotOrigin: null,
            depotDestiny: null,
            appro: props.appro,

        } as Form);


        let DepotOrigin = { ...Depot }
        let DepotDestiny = { ...Depot }
        const nombreArticle = ref(1);

        /**
       * Permet de savoiir si on veut recharger les données pendant le chargement
       */
        const loaded = ref(false)


        /**
         * Permet de faire un recherche d'articles en fonction de query
         *
         * @param   {string|null}   query  Query de recherche
         *
         * @return  {Promise}
         */
        const fetchArticles = async (query: string | null): Promise<any> => {
            if (query === null) query = ""
            return await Article.findBy('designation', query)
        }



        /**
         * Permet de retirer un article dans le tableau des articles
         *
         * @param   {number}  index  Index de l'article dans le tableau
         *
         * @return  {void}
         */
        const removeItem = (index: number): void => {
            form.value.articles.splice(index, 1)
            nombreArticle.value--
        }

        /**
        * Permet d'ajouter un article dans le tableau d'articles
        *
        * @param   {boolean}  increment  Si on doit incrementer le nombre d'article
        * @param   {any}      article    Objet contenant l'article a ajouter dans la tableau d'articles
        *
        * @return  {void}
        */
        const addItem = (increment: boolean = true, article: any = null): void => {
            // if (nombreArticle.value > Config.devis.MAX_ARTICLE) {
            //     Flash('error', "Message d'erreur", `Nombre d'article maximum atteint. Limite ${Config.devis.MAX_ARTICLE}`)
            //     return
            // }
            if (article === null) {
                form.value.articles.push({
                    id: null,
                    quantite: [1],
                    object: null,
                })
            } else {
                form.value.articles.push({
                    id: article.id,
                    quantite: [
                        article.pivot.quantite
                    ],

                    ...(props.appro === false ? {
                        object: {
                            id: article.id,
                            value: article.id,
                            reference: article.reference,
                            designation: article.designation,
                            quantite: article.pivot.quantite,
                            pu: article.pivot.pu,
                            label: `${article.reference} - ${article.designation} - ${article.pivot.pu}`
                        },
                    } : {})
                })
            }

            if (increment === true) nombreArticle.value++
        }



        const check = (e: { modelValue: null; }) => {

            //Recupere les articles ainsi que les prix selon chaque article du depôt

            if (typeof e == 'number') Article.all(null, null, null, 'avec-prix-vente/' + e);

            if (e.modelValue !== null) {
                Depot.errors.value.categories = null
            }
        }

        const checkDate = () => {
            Transfert.errors.value.date = null
        }

        const generateArticleArrayFromArticles = (articles: any[]) => {
            articles.forEach((article: null | undefined) => addItem(false, article))
        }

        const dateState = computed(() => {
            if (Transfert.errors.value.date && Transfert.errors.value.date.length > 0) return false
            return null
        })

        //faire en sorte que les articles déja selectionné ne soient plus affichées comme option
        let excludeOptions = ref([{ id: null }])
        let articleOptions = computed(() => {
            return Article.entities.value.
                filter((el, i) => {
                    let res = true

                    excludeOptions.value.forEach((ar) => {
                        if (el.article.id == ar.id) res = false
                    })

                    return res
                }).
                map((el, i) => el.article)
        })



        let selectOpenned = () => {
            excludeOptions.value = form.value.articles
            console.log("supprimer les valeurs déjà selectionnés")
        }

        /**
        * Permet de determiner si on doit charger le select durant le chargement
        *
        * @return  {boolean}
        */
        const resolveOnLoad = computed((): boolean => {
            return (loaded.value === true && props.nouveau === false)
        })

        /**
         * Generer un tableau d'articles (Squelette d'articles)
         *
         * @param   {number}  nombreArticle  Nombre d'article a généré
         *
         * @return  {void}
         */
        const generateArticleArray = (nombreArticle: number): void => {
            for (let i = 0; i < nombreArticle; i++) {
                addItem(false)
            }
        }


        onBeforeMount(() => {

            DepotOrigin.all(3)
            DepotDestiny.all(3)
            if (props.nouveau === false && props.transfert) {
                let article = props.transfert.articles.filter((el) => el.pivot.type == 0)

                nombreArticle.value = article.length
                form.value.numero = props.transfert.numero
                form.value.date = props.transfert.date
                form.value.appro = props.appro

                // Recupere l'indentifiant des depots origine et destination
                if (props.transfert.articles.length > 0) {

                    for (let index = 0; index < 2; index++) {
                        if (props.transfert.articles[index].pivot.type == 0) form.value.depotOrigin = props.transfert.articles[index].pivot.depot_id
                        else form.value.depotDestiny = props.transfert.articles[index].pivot.depot_id
                    }
                }


                generateArticleArrayFromArticles(article)

            } else {
                form.value.appro = props.appro;
                generateArticleArray(nombreArticle.value);
            }
            loaded.value = true // Rendre le depot resolvable on load

        })



        /**
         * Quant le composant est monté
         *
         * @return  {Promise}
         */
        onMounted(async (): Promise<any> => {

            if (form.value.depotOrigin != undefined && form.value.depotOrigin != null) Article.all(null, null, null, 'avec-prix-vente/' + form.value.depotOrigin);
            loaded.value = false // Rendre le depot non resolvable on load
        })

        /**
         * Permet de reinitialiser tous les champs
         *
         * @return  {void}
         */
        const resetForm = (): void => {
            form = ref({
                numero: null,
                date: null,
                articles: [],
                livreur: null,
                depotOrigin: null,
                depotDestiny: null,
                appro: props.appro,
            })

            nombreArticle.value = 1
            generateArticleArray(nombreArticle.value)
        }

        const checkArticle = (e: { modelValue: string; }, index) => {

            if (typeof e == 'number') {

                let prixArticleSelect = Article.entities.value.filter((el, i) => el.article.id == e)
                    .map((el, i) => el.prix)

                // attaché les prix existant à l'input
                form.value.articles[index - 1].prix = prixArticleSelect[0]

            }
            if (form.value.articles.length > 1 && e.modelValue !== null) {
                let find = form.value.articles.filter((article: any) => parseInt(article.id) === parseInt(e.modelValue))
                if (find.length > 1) {
                    let i = 0
                    form.value.articles.map((article: any) => {
                        if (parseInt(article.id) === parseInt(e.modelValue)) {
                            if (i === 1) {
                                Flash('danger', "Information", "Cette article existe déja dans votre liste")
                                article.id = null
                            }
                            i++
                        }
                    })
                }
            }
        }



        /**
         * Permet d'enregistrer le devis
         *
         * @return  {Promise}
         */
        const save = async (): Promise<any> => {
            let data = form.value
            const formData = new FormData()

            Object.keys(data).forEach((key) => {
                if (key !== 'file' && key !== 'articles') formData.append(key, data[key] ?? '')
            })

            if (form.value.articles) {
                formData.append('articles', JSON.stringify(form.value.articles))
            }

            if (props.nouveau === true) {
                await Transfert.create(formData)

                if (Transfert.success.value !== null) {
                    resetForm()
                    //setDevisKey()
                }

            } else if (props.transfert) {
                await Transfert.update(props.transfert.id, formData)
            }

            window.scrollTo({ top: 0, behavior: 'smooth' })
            Transfert.success.value = null
        }



        return {
            Transfert, form, appro: props.appro, check, checkDate, fetchArticles, removeItem, resolveOnLoad,
            DepotOrigin, DepotDestiny, nombreArticle, dateState, addItem, Article, save, checkArticle, articleOptions, selectOpenned, excludeOptions

        }
    }
})

