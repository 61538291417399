
import store from '../../store';
import { defineComponent, ref, computed } from 'vue';
import { formatDate, totalHT, totalTVA, totalTTC, format, montantTVA, montantTTC } from '../../functions/functions';

export default defineComponent({
    props: {
        devis: {
            type: Object,
            required: true,
        },
        appro: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    setup(props) {
        const piece = ref(props.devis.file_path === null ? null : `http://localhost:8000/storage/${props.devis.file_path}`)
        const infoEntreprise = store.state.infoEntreprise

        const assujeti = computed((): boolean => {
            return infoEntreprise.generale.assujeti
        })

        return {
            formatDate, totalHT, totalTVA, totalTTC, format, montantTVA, montantTTC, piece, infoEntreprise, assujeti,
        }
    },

});

