
import { defineComponent } from 'vue'
import DevisFormComponent from '../../../components/devis/DevisFormComponent.vue'

export default defineComponent({

    components: {
        DevisFormComponent
    },

});

