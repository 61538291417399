
import Flash from "../../functions/Flash";
import useCRUD from "../../services/CRUDServices";
import DeleteBtn from "../../components/html/DeleteBtn.vue";
import { defineComponent } from "vue";
import VueSimpleAlert from "vue3-simple-alert";

const { deleting, destroy } = useCRUD("/fournisseur");

export default defineComponent({
  components: {
    DeleteBtn,
  },

  props: {
    fournisseurs: {
      type: Array<any>,
      required: true,
    },
  },

  setup(props) {
    const confirmDeletion = async (id: number, index: number): Promise<any> => {
      VueSimpleAlert.confirm(
        "Voulez-vous supprimer ce fournisseur ?",
        "Question",
        "question"
      )
        .then(() => {
          Flash("loading", "Chargement", "Suppression en cours", 1, false);
          destroy(id, props.fournisseurs, index);
        })
        .catch((error) => {
          if (error !== undefined) {
            Flash(
              "error",
              "Message d'erreur",
              "Impossible de supprimer ce fournisseur"
            );
          }
        });
    };

    return {
      deleting,
      destroy,
      confirmDeletion,
    };
  },
});
