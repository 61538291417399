
import { Skeletor } from 'vue-skeletor';
import ProfileAvatar from 'vue-profile-avatar'
import { last } from '../../functions/functions';
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        article: {
            type: Object,
            required: true,
        }
    },

    setup() {
        return {
            last,
        }
    },

    components: {
        Skeletor, ProfileAvatar,
    },

});
