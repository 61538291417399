
import router from '../../router/router';
import { defineComponent, onBeforeMount, Ref, ref } from 'vue';
import axiosClient from '../../axios';
import Flash from '../../functions/Flash';
import SaveBtn from '../../components/html/SaveBtn.vue';

type Article = {
    reference: string,
    designation: string,
    quantite: number|null,
    pu: number,
}

export default defineComponent({
    setup() {
        const id: Ref<number> = ref(0);
        const fieldNumber: Ref<number> = ref(0);
        const articles: Ref<Array<Article>> = ref([]);
        const article: Ref<any> = ref(null);
        const loading = ref(false);
        const creating = ref(false);
        const QUANTITE_MAX: Ref<number> = ref(0);

        const addItem = (quantite: number | null = null) => {
            if (fieldNumber.value > 2) {
                Flash("error", "Message d'erreur", `Vous avez atteint la limite de nombre de champ. Limite max: ${3}`);
                return;
            }
            fieldNumber.value++;
            articles.value.push({
                reference: article.value.reference,
                designation: article.value.designation,
                quantite: quantite,
                pu: article.value.fullArticle === null ? 0 : article.value.fullArticle.pivot.pu
            });
            checkQuantite(fieldNumber.value - 1);
        };
        const checkQuantite = (index: number) => {
            let quantiteTotal = 0;
            articles.value.forEach((article, index) => {
                if (index > 0 && article.quantite) {
                    quantiteTotal += article.quantite;
                }
            });
            if (quantiteTotal > QUANTITE_MAX.value) {
                articles.value[index].quantite = null;
                Flash("error", "Message d'erreur", "La quantité maximale est dépassé");
            }
        };

        const removeItem = (index: number) => {
            articles.value.splice(index, 1);
            fieldNumber.value--;
        };

        const save = async (): Promise<any> => {
            creating.value = true;
            try {
                await axiosClient.post(`/depot/${id.value}/gerer-prix/${article.value.article_id}`, { articles: articles.value });
                Flash('success', "Message de succès", "Enregistré avec succès");
            } catch (error: any) {
                if (error.response.status === 422) {
                    Flash('error', "Message d'erreur", error.response.data.message)
                }
            }
            creating.value = false;
        };

        onBeforeMount(async (): Promise<any> => {
            const depotId = parseInt(router.currentRoute.value.params.depot_id.toString());
            const articleId = parseInt(router.currentRoute.value.params.article_id.toString());
            id.value = depotId;
            loading.value = true;
            let response = await axiosClient.get(`/depot/${depotId}/articles/?article_id=${articleId}`);
            article.value = response.data;
            loading.value = false;

            // Recuperer la reference de l'article ainsi que la quantité
            if (article.value !== null) {
                addItem();
                QUANTITE_MAX.value = parseFloat(article.value.entree ?? 0) - parseFloat(article.value.sortie ?? 0);
            }
        });
        return {
            id,
            addItem,
            fieldNumber,
            removeItem,
            articles,
            loading,
            QUANTITE_MAX,
            checkQuantite,
            save,
            creating,
        };
    },
    components: { SaveBtn }
})
