
import { formatDate, expiration } from '../../functions/functions';
import { computed, defineComponent } from 'vue';
import Flash from '../../functions/Flash';
import DeleteBtn from '../html/DeleteBtn.vue';
import useCRUD from '../../services/CRUDServices';
import SimpleAlert from 'vue3-simple-alert';
import Status from '../html/Status.vue';

const { destroy } = useCRUD('/commandes');

export default defineComponent({
    components: {
        DeleteBtn,
        SimpleAlert,
        Status
    },

    props: {
        entities: {
            type: Array<any>,
            required: true,
        },
        appro: {
            type: Boolean,
            default: true,
            required: false,
        },
        commande: {
            type: Boolean,
            default: false,
            required: false,
        },
    },

    setup(props) {
        const confirmDeletion = async (id: number, index: number): Promise<any> => {
            await SimpleAlert.confirm("Voulez-vous supprimer ce devis ?", "Suppression", "question").then(() => {
                Flash('loading', "Chargement", "Suppression en cours", 1, false)
                destroy(id, props.entities, index)
            }).catch((error: undefined) => {
                if (error !== undefined) {
                    Flash('error', "Message d'erreur", "Impossible de supprimer ce point de vente")
                }
            });
        }

        const type = computed(() => {
            if (props.appro === true) return "fournisseur"
            return "client"
        })

        return {
            Flash, formatDate, expiration, destroy, confirmDeletion, type,
        }
    }

});

