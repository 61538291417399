
import useCRUD from '../../services/CRUDServices';
import EtiquetteFormLoadingComponent from '../../components/etiquette/FormEtiquetteComponent.vue';
import { onBeforeMount } from 'vue';
import router from '../../router/router';

const { loading, entity, find } = useCRUD('/etiquette');

export default {
    setup() {
        onBeforeMount(async (): Promise<any> => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            await find(id);
        })

        return {
            loading, entity,
        }
    },

    components: {
        EtiquetteFormLoadingComponent
    },

}
