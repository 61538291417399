

import router from '../../../router/router';
import { onBeforeMount } from 'vue';
import useCRUD from '../../../services/CRUDServices';
import CategorieFormComponent from '../../../components/categorie/CategorieFormComponent.vue';
import CategorieFormLoadingComponent from '../../../components/categorie/CategorieFormLoadingComponent.vue';

const { find, loading, entity } = useCRUD('/categorie');

export default {
    setup() {
        onBeforeMount(async (): Promise<any> => {
            const id = parseInt(router.currentRoute.value.params.id.toString());
            await find(id);
        })

        return {
            loading, find, entity,
        }
    },

    components: {
        CategorieFormComponent, CategorieFormLoadingComponent,
    },

}
