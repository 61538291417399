<template>
    <table class="table table-striped">
        <thead class="bg-secondary text-white">
            <tr>
                <th>Utilisateur</th>
                <th>Dépôt</th>
                <th>Référence</th>
                <th>Designation</th>
                <th>Stock</th>
                <th>Motif</th>
                <th>Date</th>
            </tr>
        </thead>
        <tbody v-if="entities.length > 0">
            <tr v-for="(historique, index) in entities" :key="historique.id">
                <td class="align-middle">{{ historique.nom_personnel + " " + historique.prenoms_personnel }}</td>
                <td class="align-middle">{{ historique.nom_depot }}</td>
                <td class="align-middle">{{ historique.reference_article }}</td>
                <td class="align-middle">{{ historique.designation_article }}</td>
                <td class="align-middle">{{ historique.stock }}</td>
                <td class="align-middle text-capitalize">{{ historique.motif }}</td>
                <td class="align-middle">{{ historique.date }}</td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td class="text-center" colspan="9">Aucune donnée</td>
            </tr>
        </tbody>
    </table>
</template>
<script>

export default {
    components: {},

    props: {
        entities: {
            type: Array,
            required: true,
        },
    },
}

</script>