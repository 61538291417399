
import CategorieFormComponent from '../../../components/categorie/CategorieFormComponent.vue';
import { defineComponent } from 'vue';

export default defineComponent({

    components: {
        CategorieFormComponent
    },

})

