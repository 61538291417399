import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge bg-success"
}
const _hoisted_2 = {
  key: 1,
  class: "badge bg-danger"
}
const _hoisted_3 = {
  key: 2,
  class: "badge bg-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.value === 1)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Valide"))
      : _createCommentVNode("v-if", true),
    (_ctx.value === 2)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Expiré"))
      : _createCommentVNode("v-if", true),
    (_ctx.value === 3)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Livré"))
      : _createCommentVNode("v-if", true)
  ]))
}