
import { defineComponent, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import TransfertListComponent from '../../components/transfert-article/TransfertListComponent.vue'

export default defineComponent({
    components: {
        TransfertListComponent
    },
    setup() {
        const route = useRoute()

        onMounted(() => {
            const id = route.params.id
            console.log(id)
        })
    }
});



