

import { defineComponent } from 'vue';
import ClientFormComponent from '../../components/client/ClientFormComponent.vue';

export default defineComponent({
    components: {
        ClientFormComponent,
    }
});
