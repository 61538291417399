<template>
    <div class="col-xl-12">
        <div class="card me-3">
            <div class="card-header bg-white p-3">
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="text-info">Liste des clients</h5>
                    <router-link to="/client/nouveau" class="btn btn-primary"><i class="fa fa-plus me-2"></i>Nouveau
                        client</router-link>
                </div>
            </div>
            <div class="card-body">
                <ListeClientLoadingComponent v-if="loading" />
                <ListeClientComponent v-else :clients="getLaravelData(entities)" />
            </div>
            <pagination-bootstrap :totalPages="pagination.totalPages" :currentPage="pagination.currentPage"
                @update:currentPage="getPage"></pagination-bootstrap>
        </div>
    </div>
</template>

<script>

import useCRUD from '../../services/CRUDServices';
import { defineComponent, onBeforeMount, provide } from 'vue';
import ListeClientComponent from '../../components/client/ListeClientComponent.vue';
import ListeClientLoadingComponent from '../../components/client/ListeClientLoadingComponent.vue';

import PaginationBootstrap from "../../components/utils/PaginationBootstrap/PaginationBootstrap.vue";
import PaginationBootstrapMixins from "../../components/utils/PaginationBootstrap/PaginationBootstrapMixins.js";
import ParserService from '../../services/ParserService.js';

const { loading, entities, all } = useCRUD("/client")

export default defineComponent({
    components: { ListeClientComponent, ListeClientLoadingComponent, PaginationBootstrap },

    mixins: [PaginationBootstrapMixins],

    setup() {
        provide('ParserService', ParserService);

        return {
            entities, loading, all,
        }
    },

    data() {
        return {
            page: 1,
        }
    },

    mounted() {
        this.loadData();
    },

    methods: {
        async getPage(page) {
            this.page = page;
        },
        loadData() {
            all(1, JSON.stringify({ page: this.page, perPage: this.pagination.perPage }));
        },
        getLaravelData(response) {
            var pagination = this.getLaravelPagination(response);
            this.pagination.currentPage = pagination.currentPage;
            this.pagination.totalPages = pagination.totalPages;
            return ParserService.getLaravelData(response);
        },
        getLaravelPagination(response) {
            return ParserService.getLaravelPagination(response);
        }
    },

    watch: {
        page() {
            this.loadData();
        }
    }
});

</script>
