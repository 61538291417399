import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { for: "input" }
const _hoisted_2 = { class: "row mt-3" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("input", {
      type: "file",
      multiple: false,
      name: "input",
      id: "input",
      class: "form-control",
      accept: "image/png, image/jpeg, .pdf",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args)))
    }, null, 32 /* HYDRATE_EVENTS */),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paths, (path) => {
        return (_openBlock(), _createElementBlock("div", {
          key: path,
          class: "col-xl-12"
        }, [
          _createElementVNode("iframe", {
            src: path,
            frameborder: "1",
            width: "100%",
            height: "500px"
          }, null, 8 /* PROPS */, _hoisted_3)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 64 /* STABLE_FRAGMENT */))
}